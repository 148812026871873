import { getFunctions, httpsCallable } from 'firebase/functions'

const BEAT_INTERVAL = 60

export const state = () => ({
})

export const mutations = {
}

export const getters = {
  beatIntervalMs: () => BEAT_INTERVAL * 1000
}

export const actions = {
  async beat ({ rootGetters }, { event, projectId, sessionId }) {
    if (!projectId || !rootGetters['auth/signedIn']) {
      return
    }

    try {
      await httpsCallable(getFunctions(), 'beat')({ event, projectId, sessionId })
    } catch (error) {
      if (error.code === 'functions/already-exists') {
        return
      }
      throw error
    }
  },

  async playerBeat ({ dispatch }, { projectId, sessionId }) {
    await dispatch('beat', { event: 'playerbeat', projectId, sessionId })
  }
}
