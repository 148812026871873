export default class ProjectUserExists {
  constructor (data = {}) {
    this.exists = !!data.exists
    this.linked = !!data.linked
    this.name = data.name
    this.email = data.email
    this.uid = data.uid
    Object.freeze(this)
  }
}
