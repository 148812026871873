import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=6e652ed9&scoped=true&"
import script from "./Navigation.vue?vue&type=script&lang=js&"
export * from "./Navigation.vue?vue&type=script&lang=js&"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=6e652ed9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e652ed9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VNavigationDrawer})
