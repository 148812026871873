import ProjectForm from './auth/ProjectForm'
import { AuthWorkflow } from './auth/AuthWorkflow'

export default class Project {
  constructor (data = {}) {
    this.id = data.id
    this.pid = data.pid
    this.status = data.status
    this.trackingEvents = data.trackingEvents || {}
    this.form = new ProjectForm(data.form)

    this.registrationEnabled = data.registrationEnabled || false

    this.authWorkflow = new AuthWorkflow(data.authWorkflow)

    // TODO: legacy settings, to be removed
    this.registrationMagicLinkEnabled = data.registrationMagicLinkEnabled
    this.registrationPrefixEnabled = data.registrationPrefixEnabled
    this.registrationSnEnabled = data.registrationSnEnabled

    Object.freeze(this)
  }
}
