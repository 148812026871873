<template>
  <v-form ref="form" v-model="valid" lazy-validation autocomplete="off" @submit.prevent="onRegister">
    <v-card-text>
      <v-row>
        <v-col v-if="project.form.registerDescription" cols="12" v-html="project.form.registerDescription" />
        <v-col v-else cols="12" class="pt-0 headline font-weight-light">
          {{ $t('v.register') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" class="py-3 grey--text">
          <v-icon aria-hidden="false">
            mdi-account
          </v-icon>
          {{ email }}
        </v-col>
        <field v-for="field in project.form.fields" :key="field.name" v-model="projectUserCandidate[field.name]" :field="field" :email="email" :values="projectUserCandidate" @hide="delete projectUserCandidate[field.name]" />
      </v-row>

      <v-row align="center">
        <v-col cols="auto">
          <v-btn :small="project.form.small" nuxt text @click="previous">
            {{ $t('v.back') }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn large :small="project.form.small" :disabled="!registerEnabled" :loading="submitting" color="accent" class="px-11" type="submit">
            {{ $t('v.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepMixin from './StepMixin'
import Field from '@/components/auth/Field'

export default {
  components: {
    Field
  },

  mixins: [
    StepMixin
  ],

  props: {
    email: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    projectUserCandidate: {},
    valid: false,
    submitting: false
  }),

  computed: {
    registerEnabled () {
      return this.valid && !this.submitting
    },

    ...mapGetters({
      project: 'projects/project'
    })
  },

  methods: {
    async onRegister () {
      if (!this.$refs.form.validate()) {
        return false
      }

      try {
        this.submitting = true
        await this.wdotAdd({ event: 'tryRegister', email: this.email, newProjectUser: {} }) // TODO: add this.newProjectUser preventing undefined elements
        await this.registerProjectUser({ ...this.projectUserCandidate, email: this.email })
        this.next()
      } catch (error) {
        this.showError(this.$t(error.message))
        this.$logError(error)
        if (error.message === 'v.emailInvalid') {
          this.previous()
        }
      } finally {
        this.submitting = false
      }
    },

    ...mapActions({
      registerProjectUser: 'auth/registerProjectUser',
      wdotAdd: 'wdots/add',
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
</style>
