export const QuestionMode = Object.freeze({
  ALL_AT_ONCE: 'all-at-once',
  ONE_BY_ONE: 'one-by-one',

  all: () => Object.values(QuestionMode).filter(mode => typeof mode === 'string'),

  fromString: (string) => {
    const mode = QuestionMode.all().find(mode => mode === string)
    if (!mode) {
      console.warn(`Unknown Q mode: ${string}`) // eslint-disable-line no-console
      return null
    }
    return mode
  }
})

export class Q {
  constructor (data = {}) {
    this.id = data.id
    this.name = data.name
    this.enabled = !!data.enabled
    this.title = data.title
    this.description = data.description
    this.questions = data.questions || []
    this.questionMode = QuestionMode.fromString(data.questionMode) || QuestionMode.ALL_AT_ONCE
    this.showQuestionIndex = !!data.showQuestionIndex

    this.assesmentEnabled = data.assesmentEnabled
    this.passingScore = data.passingScore || 0
    this.maxRetryCount = data.maxRetryCount == null || !this.assesmentEnabled ? 0 : data.maxRetryCount

    this.submitMessage = data.submitMessage
    this.successMessage = data.successMessage
    this.failMessage = data.failMessage
    this.retryMessage = data.retryMessage

    Object.freeze(this)
  }

  get hasQuestions () {
    return this.questions.length > 0
  }

  get questionsAllAtOnce () {
    return this.questionMode === QuestionMode.ALL_AT_ONCE
  }

  get questionsOneByOne () {
    return this.questionMode === QuestionMode.ONE_BY_ONE
  }
}
