<template>
  <v-checkbox v-model="computedValue" :hint="field.hint" :dense="field.dense" :rules="rules" persistent-hint class="pa-0">
    <template #label>
      <span class="no-clickable w-full body-2" @click.prevent.stop v-html="label" />
    </template>
  </v-checkbox>
</template>

<script>
import iterator from 'markdown-it-for-inline'

export default {
  name: 'CheckField',

  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      type: Boolean,
      default: null
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    rules () {
      if (!this.field.required) {
        return []
      }
      return [v => !!v || this.$t('v.fieldRequired')]
    },

    label () {
      if (!this.field.label) {
        return ''
      }
      return this.$md.use(iterator, 'url_new_win', 'link_open', (tokens, idx) => {
        const token = tokens[idx]
        token.attrPush(['target', '_blank'])
        token.attrPush(['onclick', 'event.stopPropagation()'])
      }).renderInline(this.field.label)
    }
  }
}
</script>
