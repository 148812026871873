export class HttpError extends Error {
  constructor (status, ...params) {
    super(...params)
    this.name = 'HttpError'
    this.status = status
  }
}

export class AuthError extends HttpError {
  constructor (status, ...params) {
    super(status, ...params)
    this.name = 'AuthError'
  }
}

export class NotFoundError extends HttpError {
  constructor (...params) {
    super(404, ...params)
    this.name = 'NotFoundError'
  }
}
