<template>
  <v-dialog v-if="disclaimer" :value="!!disclaimer" overlay-opacity="1" max-width="600" persistent>
    <v-card>
      <v-card-title>
        {{ disclaimer.title || $t('v.disclaimer') }}
      </v-card-title>
      <v-card-text v-if="disclaimer.content" v-html="disclaimer.content" />
      <v-card-actions class="pb-4">
        <v-spacer />
        <v-btn color="accent" class="px-9" @click="acceptDisclaimer">
          {{ disclaimer.ctaLabel || $t('v.disclaimerAccept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Disclaimer',

  computed: {
    ...mapGetters({
      disclaimer: 'sites/disclaimer'
    })
  },

  methods: {
    acceptDisclaimer () {
      this.acceptDisclaimer()
    },

    ...mapActions({
      acceptDisclaimer: 'sites/acceptDisclaimer'
    })
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}

.v-card {
  -webkit-overflow-scrolling: touch !important;
}
</style>
