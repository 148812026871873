import { omit } from '@/helpers'

export const AuthWorkflowType = Object.freeze({
  DEFAULT: Object.freeze({ id: 'default', component: 'DefaultWorkflow' }),
  EMAIL_PASSWORD: Object.freeze({ id: 'email-password', component: 'EmailPasswordWorkflow' }),
  SN_FULL: Object.freeze({ id: 'sn-full', component: 'SnFullWorkflow' }),

  all: () => Object.values(AuthWorkflowType).filter(workflow => typeof workflow === 'object'),

  fromString: (string) => {
    const workflow = AuthWorkflowType.all().find(workflow => workflow.id === string)
    if (!workflow) {
      throw new Error(`Unknown auth workflow type: ${string}`)
    }
    return workflow
  }
})

export class AuthWorkflow {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this.type = data.type ? AuthWorkflowType.fromString(data.type) : AuthWorkflowType.DEFAULT

    Object.assign(this, omit(data, 'type'))

    Object.freeze(this)
  }
}
