<template>
  <div>
    <div class="container">
      <img width="64" height="64" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAilBMVEUAAAAyMjIlJSUyMjIyMjIyMjIyMjIxMTEuLi4vLy8yMjIyMjIxMTEwMDAyMjIyMjIxMTEwMDAwMDArKysxMTEyMjIyMjIyMjIxMTEvLy8yMjIyMjIyMjIwMDAwMDAwMDAxMTEvLy8yMjIxMTEyMjIyMjIyMjIzMzMyMjIxMTEwMDAzMzMyMjIzMzNrgPkPAAAALXRSTlMAhgjv+6vKsyUh9J9aGuXAjkgqFMTx3tFFP+nXi1RLMmwOpJTauq1kTDlfX8wGOjAqAAAEIUlEQVR42ryY3XaqMBCFhxASfgVFxFZFa2vV9uT9X++s1YsyCWAngPkuXUvYkj2zN8Iooua0D/J64cecx/6izoP9qYnACdmhWFeql2pdHDJ4Jmxb7NQf7Iotg6fAlomvSPjJcn4NkbdQFiy8eR3xIpU18gXm4ibUKMQN5mAr1GjEFqYSSjUJGU5zvsfVRLjHJnjvQz0g3oiLDAJ5EZtYPeBjrBtZoQZ4lV56LAFRHlNPvqoBCgYjWNX9Wya4hoOGuQb9u6pegTWHirDjyPuyOoAle9UlTxkQYGmuuuzBBvbZdfP5CGSOZ65MPhmQKUXn9klkmR1JR4IoyZHfydx8NcLEeSepM6L4jRltKYwiNQN0E5F+v3n/9zcYydu7qSAjnP/OWDpLmMDy1TiFP33ADP+tM5hEtjacyOAxxvx5DKbiGdNos3/4N8zAF6dvpIMedw3MQqOH5eHB6FYK4d9hJu6+lgurQQPW2v1XMBsrTUE9ZCwt/+M7zMhdO4VioP9o/mtgVhrNib0diWn96xtm5ktraeyvefWACGNAgHL9kOP9x0ip7YlYqfifR8tZvBN5CCYS7/+M1Jl/re1vgUCGc0GCwVYhlqTrVWhkIlIyKYSpWeD8BQpa1CbWXxGgccP9g5b/Wm3waf0AN5Tb4ANIafZXGjTRKX4EQzsoJ7YdpZEBiXxgG0k0INQI4ArDiKHAewchotsJjzWiBiKJaon6lhSPgMhJIU5AJOI965Ahc56BCluTmx7mjMaN9SyIo0XpPv+qtijux56Fl+ARsCE8JVImpxBsyDt+Yz7eAU8nReuLoRhAnzwX5puBUOBn4oDE7GY77AoHIM/vfmJ15AmUIaIceQaZ/jISAMK2YtEJ0EuKboGrGwFXZAJ9p4duBISofAJA1VZBcCMA2nJYaUkoXQmQOBEbdBVHAvBXG5yqqSsBKc7xPUpCRwJwIu7xUJauBJRo9aB0jMGVAIhR/tftn3juBGxQmWzrmHAnQLS1DNpkuLgTcGnzDx2HdCdAIuNxZEhHAnAe8v/NmzEKgEAQAys7X+L/H2grTCPkwsRaMKDe3SYTX4D+CvSPUP8N9YVIX4r1zUjfjvUDiX4k0w+l+rHcH0z00UwfTjGeFwVwPIdBURVAgwIWTVcALRqaVFUBNKlo01UF0KajUdkVQKOSVm1VAK1amtVdATSraddXBdCuZ2BRE8DAIohsKCCIbBBadQQwtEpiOwoIYjsGlw0BDC6T6JYCouiW4XVBAMLrKL6ngCy+J8Dw54O6PtcdAQz5rBe/MkIsz9GHE2JZxHh8kMlHuXSYzcf5ADTiX2gDjUQ6cUOMdK5DrTrW64PNPtrtw+0+3u8XHPyKx0DJxa/5+EUnv+o1UHbz634DhceByudA6XWg9jtQfB6ofi+U3xv1/xe7ahbq4NHVlwAAAABJRU5ErkJggg==">
      <h1>Oooops!</h1>
      <div class="content">
        <div v-if="error.statusCode === 404">
          <p>Este sitio no existe o ha sido eliminado.</p>
          <p>This page does not exist or has been removed.</p>
        </div>
        <div v-else>
          <p>{{ error.statusCode }} {{ error.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
.container {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   text-align: center;
   font-family: sans-serif;
}

h1 {
  margin-top: 8px;
}

.content p {
  margin-top: 16px;
}
</style>
