import { getFirestore, collection, query, where } from 'firebase/firestore'
import { subscribe, keysRemoveTrailingUnderscore, cloneDeep } from '@/helpers'
import UserDots from '@/models/UserDots'
import { parseEvaluateCondition } from '@/models/ConditionSemantics'

export const state = () => ({
  userDots: null,

  userDotsUnsubscribe: null,

  userDotsLoading: false
})

export const getters = {
  userDots: state => state.userDots,

  userDotsLoading: state => state.userDotsLoading,

  conditionContext: (state, getters, rootState, rootGetters) => {
    const userDots = getters.userDots

    const data = {}

    if (userDots) {
      Object.assign(data, cloneDeep(keysRemoveTrailingUnderscore(userDots)))
      data.loaded = true
    }

    const siteSession = rootGetters['projects/siteSession']

    if (siteSession) {
      data.session = {
        status: siteSession.status
      }

      if (data.stats && data.stats.sessions && data.stats.sessions[siteSession.id]) {
        data.stats.sessions.siteSession = data.stats.sessions[siteSession.id]
      }
    }

    const signedIn = rootGetters['auth/signedIn']
    if (signedIn) {
      data.signedIn = true
    }

    return data
  },

  evaluateCondition: (state, getters) => (condition) => {
    return parseEvaluateCondition(condition, getters.conditionContext)
  }
}

export const mutations = {
  setUserDots (state, userDots) {
    state.userDots = userDots
  },

  setUserDotsUnsubscribe (state, unsubscribe) {
    state.userDotsUnsubscribe = unsubscribe
  },

  setUserDotsLoading (state, loading) {
    state.userDotsLoading = loading
  }
}

function dataToUserDots (id, data) {
  const modified = data.modified && data.modified.toDate ? data.modified.toDate() : null
  return new UserDots({ ...data, id, modified })
}

export const actions = {
  async initUserDots ({ commit, getters, rootGetters }) {
    const site = rootGetters['sites/site']
    const user = rootGetters['auth/user']

    if (!site || !site.projectId || !user) {
      return
    }

    commit('setUserDotsLoading', true)

    if (state.userDotsUnsubscribe) {
      state.userDotsUnsubscribe()
      commit('setUserDotsUnsubscribe', null)
    }

    const unsubscribe = await subscribe(query(collection(getFirestore(), 'projects', site.projectId, 'userDots'), where('uid', '==', user.uid)), {
      init (docs) {
        const userDots = docs.map(doc => dataToUserDots(doc.id, doc.data()))
        commit('setUserDots', userDots[0] || new UserDots())
      },
      update (doc) {
        commit('setUserDots', dataToUserDots(doc.id, doc.data()))
      },
      remove (doc) {
        commit('setUserDots', new UserDots())
      }
    })

    commit('setUserDotsUnsubscribe', unsubscribe)
    commit('setUserDotsLoading', false)
  },

  resetUserDots ({ commit, state }) {
    commit('setUserDots', null)
    if (state.userDotsUnsubscribe) {
      state.userDotsUnsubscribe()
      commit('setUserDotsUnsubscribe', null)
    }
  }
}
