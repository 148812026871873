<template>
  <component :is="workflow.type.component" :workflow="workflow" @success="success" @close="close" />
</template>

<script>
import * as workflows from './workflows'
import WorkflowMixin from './WorkflowMixin'

export default {
  components: {
    ...workflows
  },

  mixins: [
    WorkflowMixin
  ],

  props: {
    workflow: {
      type: Object,
      required: true
    }
  }
}
</script>
