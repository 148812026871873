export const state = () => ({
  googleAnalyticsInitialized: null
})

export const mutations = {
  setGoogleAnalyticsInitialized (state, initialized) {
    state.googleAnalyticsInitialized = initialized
  }
}

window.dataLayer = window.dataLayer || []

function gtag () {
  window.dataLayer.push(arguments)
}

export const actions = {
  initGoogleAnalytics ({ state, dispatch, commit, rootGetters }) {
    const googlePropertyId = process.env.GOOGLE_PROPERTY_ID
    const googleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID

    if (!googlePropertyId) {
      commit('setGoogleAnalyticsInitialized', false)
      return
    }

    const site = rootGetters['sites/site']

    if (!site) {
      return
    }

    gtag('js', new Date())

    gtag('config', googlePropertyId, { send_page_view: false })
    gtag('config', googleAnalyticsId, { send_page_view: false })

    if (site.googlePropertyId) {
      gtag('config', site.googlePropertyId, { send_page_view: false, groups: 'customer' })
    }

    if (site.googleAdsPropertyId) {
      gtag('config', site.googleAdsPropertyId, { send_page_view: false, groups: 'customer' })
    }

    commit('setGoogleAnalyticsInitialized', true)
  },

  gtag ({ state, dispatch }, params) {
    if (!Array.isArray(params)) {
      throw new Error('gtag params should be an array')
    }

    if (state.googleAnalyticsInitialized === null) {
      dispatch('initGoogleAnalytics')
    }
    if (!state.googleAnalyticsInitialized) {
      return
    }

    gtag(...params)
  },

  visitPage ({ dispatch, rootGetters }, path) {
    const site = rootGetters['sites/site']
    dispatch('gtag', ['event', 'page_view', { page_path: path }])

    if (site.googlePropertyId || site.googleAdsPropertyId) {
      dispatch('gtag', ['event', 'page_view', { page_path: path, send_to: ['customer'] }])
    }
  },

  viewVideo ({ dispatch }, video) {
    if (!video.vid) {
      return
    }

    dispatch('gtag', ['event', 'play', { event_category: 'Videos', event_label: video.vid }])
  },

  signInOpen ({ dispatch }, projectId) {
    dispatch('gtag', ['event', 'open', { event_category: 'signIn', event_label: projectId }])
  },

  successfulRegistration ({ dispatch, rootGetters }, projectId) {
    dispatch('gtag', ['event', 'successfulRegistration', { event_label: projectId }])

    const site = rootGetters['sites/site']

    if (site.googlePropertyId) {
      dispatch('gtag', ['event', 'Registro', { send_to: `${site.googlePropertyId}` }])
    }

    if (site.googleAdsPropertyId && site.googleAdsLabel) {
      dispatch('gtag', ['event', 'conversion', { send_to: `${site.googleAdsPropertyId}/${site.googleAdsLabel}` }])
    }

    if (site.fbPixelId) {
      window.fbq('track', 'CompleteRegistration')
    }
  }
}
