<template>
  <v-snackbar :value="notificationVisible" :timeout="notification.timeout" :color="notification.level || 'info'" bottom multi-line @input="hide">
    <v-row>
      <v-col cols="11">
        <div v-if="notification.techMessage" class="opacity-5">
          {{ notification.techMessage }}
        </div>
        {{ notification.message }}
      </v-col>
      <v-col cols="1" class="text-right pr-0 pl-2">
        <v-btn text small icon dark @click.native="hide()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Notification',

  data: () => ({
    notification: {},
    notificationVisible: false
  }),

  computed: {
    ...mapGetters({
      notifications: 'notification/notifications'
    })
  },

  watch: {
    notifications () {
      this.updateCurrentNotification()
    }
  },

  mounted () {
    this.updateCurrentNotification()
  },

  methods: {
    updateCurrentNotification () {
      const currentNotification = this.notifications[0]
      if (this.notification !== currentNotification) {
        this.notification = currentNotification || {}
        this.notificationVisible = !!currentNotification
      }
    },

    hide (value) {
      if (!value) {
        this.notificationVisible = false
        setTimeout(() => this.hideNotification(), 300)
      }
    },

    ...mapActions({
      hideNotification: 'notification/hideNotification'
    })
  }
}
</script>

<style>
</style>
