const Language = Object.freeze({
  ENGLISH: Object.freeze({ code: 'en', name: 'English', localName: 'English' }),
  SPANISH: Object.freeze({ code: 'es', name: 'Spanish', localName: 'Español' }),
  PORTUGUESE: Object.freeze({ code: 'pt', name: 'Portuguese', localName: 'Português' }),
  CHINESE: Object.freeze({ code: 'zh', name: 'Chinese', localName: '汉语' }),

  all: () => Object.values(Language).filter(value => typeof value === 'object'),

  fromString: (string) => {
    const language = Language.all().find(language => language.code === string)
    if (!language) {
      throw new Error(`Unknown language: ${string}`)
    }
    return language
  }
})

export default Language
