class Gender {
  constructor (data) {
    if (!data.id) {
      throw new Error('Missing gender id')
    }

    this.id = data.id
    Object.freeze(this)
  }

  get name () {
    return `v.gender.${this.id}`
  }
}

const Genders = {
  MALE: new Gender({ id: 'M' }),
  FEMALE: new Gender({ id: 'F' }),

  all: () => Object.values(Genders)
    .filter(gender => gender instanceof Gender),

  fromString: (string) => {
    const gender = Genders.all().find(gender => gender.id === string)
    if (!gender) {
      throw new Error(`Unknown gender: ${string}`)
    }
    return gender
  }
}
Object.freeze(Genders)

export default Genders
