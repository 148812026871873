<template>
  <v-col v-if="visible" :cols="`${field.cols}`">
    <component :is="field.component" :field="field" :email="email" :value="value" @input="update" />
  </v-col>
</template>

<script>
import * as fields from './fields'
import { parseEvaluateCondition } from '@/models/ConditionSemantics'

export default {
  components: {
    ...fields
  },

  props: {
    field: {
      type: Object,
      required: true
    },

    email: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Boolean],
      default: null
    },

    values: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    visible () {
      return parseEvaluateCondition(this.field.condition, this.values)
    }
  },

  watch: {
    visible (value) {
      // Delete field if it was hidden
      if (!value && this.value != null) {
        this.$emit('hide')
      }
    }
  },

  methods: {
    update (value) {
      this.$emit('input', value)
    }
  }
}
</script>
