const Sectors = {
  ARTE: 'Arte y Diseño',
  CONSTRUCCION: 'Construcción, Inmobiliaria y Arquitectura',
  FINANCIERO: 'Financiero, Consultoría y Fintech ',
  DEPORTES: 'Deportes',
  DERECHO: 'Derecho y Leyes',
  GASTRONOMIA: 'Gastronomía, Alimentos y Bebidas',
  TURISMO: 'Turismo y Entretenimiento ',
  MEDIOS: 'Medios de Comunicación',
  TEXTIL: 'Textil, Moda y Belleza',
  ACTRIZ: 'Actriz, Actor e Influencer',
  TELECOMUNICACIONES: 'Telecomunicaciones y TI',
  ECOMMERCE: 'Ecommerce ',
  VETERINARIA: 'Veterinaria, Agricultura y Biodiversidad',
  MANUFACTURA: 'Manufactura, Producción y Operación',
  MERCADOTECNIA: 'Mercadotecnia, Publicidad y Relaciones Públicas',
  LOGISTICA: 'Logística, Distribución y Almacen',
  SEGUROS: 'Seguros y Reaseguros',
  ENERGIA: 'Energía',
  PETROLEO: 'Petroleo y Gas ',
  MINERIA: 'Minería y Metales',
  HEADHUNTING: 'Headhunting',
  ELEARNING: 'E-Learning',
  INGENIERIA: 'Ingeniería, Industrial y Mecánica',
  SALUD: 'Salud',
  AUTOMOTRIZ: 'Automotriz & Aeroespacial',
  ARTICULOS: 'Artículos de consumo',
  AMBIENTAL: 'Ambiental',

  all: () => Object.values(Sectors)
    .filter(sector => typeof sector === 'string')
    .sort(),

  fromString: (string) => {
    const sector = Sectors.all().find(sector => sector === string)
    if (!sector) {
      throw new Error(`Unknown sector: ${string}`)
    }
    return sector
  }
}
Object.freeze(Sectors)

export default Sectors
