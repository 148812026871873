<template>
  <v-dialog v-if="dialog" :value="!!dialog" overlay-opacity="0.8" scrollable max-width="600" @input="closeDialog">
    <v-card v-if="dialog.registration" dark>
      <v-card-title>
        {{ $t('v.register') }}
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <iframe :src="dialog.url" width="100%" height="690" frameborder="no" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dialog',

  computed: {
    ...mapGetters({
      dialog: 'dialog/dialog'
    })
  },

  methods: {
    closeDialog () {
      this.hideDialog()
    },

    ...mapActions({
      hideDialog: 'dialog/hideDialog'
    })
  }
}
</script>

<style scoped>
.v-card__title {
  word-break: break-word;
}

.v-card {
  -webkit-overflow-scrolling: touch !important;
}
</style>
