<template>
  <sn-signin-step @next="signinCompleted" />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkflowMixin from '../WorkflowMixin'
import SnSigninStep from './steps/SnSigninStep'

export default {
  components: {
    SnSigninStep
  },

  mixins: [
    WorkflowMixin
  ],

  props: {
    workflow: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      project: 'projects/project'
    })
  },

  methods: {
    signinCompleted () {
      this.showMessage(this.$t('v.signInSuccesful'))
      this.successClose()
    },

    ...mapActions({
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
</style>
