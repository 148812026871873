export default {
  methods: {
    previous () {
      this.$emit('previous')
    },

    next (value) {
      this.$emit('next', value)
    }
  }
}
