import { omit, keysRemoveTrailingUnderscore, blankToNull } from '@/helpers'

export default class ProjectUser {
  constructor (data = {}) {
    this._id = data.id
    this._email = data.email
    this._created = data.created
    this.uid = data.uid
    this.locale = data.locale
    this.meta = data.meta
    Object.assign(this, omit(keysRemoveTrailingUnderscore(data), 'id', 'uid', 'email', 'created', 'locale', 'meta'))
  }

  get id () {
    return this._id
  }

  get uid () {
    return this._uid
  }

  set uid (uid) {
    this._uid = uid
  }

  get email () {
    return this._email
  }

  get created () {
    return this._created
  }

  get locale () {
    return this._locale
  }

  set locale (locale) {
    this._locale = blankToNull(locale)
  }

  get meta () {
    return this._meta
  }

  set meta (meta) {
    this._meta = meta ? { ...meta } : {}
  }
}
