<template>
  <v-stepper v-model="step" flat>
    <v-stepper-items>
      <v-stepper-content step="email" class="py-0 px-3">
        <default-signin-step @next="signinCompleted" />
      </v-stepper-content>

      <v-stepper-content step="register" class="py-0 px-3">
        <default-register-step :email="email" @previous="goSignin" @next="registerCompleted" />
      </v-stepper-content>

      <v-stepper-content step="register-disabled" class="py-0 px-3">
        <default-disabled-step @previous="goSignin" @next="close" />
      </v-stepper-content>

      <v-stepper-content step="confirm" class="py-0 px-3">
        <default-confirm-step @next="close" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkflowMixin from '../WorkflowMixin'
import DefaultSigninStep from './steps/DefaultSigninStep'
import DefaultRegisterStep from './steps/DefaultRegisterStep'
import DefaultConfirmStep from './steps/DefaultConfirmStep'
import DefaultDisabledStep from './steps/DefaultDisabledStep'

export default {
  components: {
    DefaultSigninStep,
    DefaultRegisterStep,
    DefaultConfirmStep,
    DefaultDisabledStep
  },

  mixins: [
    WorkflowMixin
  ],

  props: {
    workflow: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    email: '',
    step: 'email'
  }),

  computed: {
    ...mapGetters({
      project: 'projects/project'
    })
  },

  methods: {
    goSignin () {
      this.step = 'email'
    },

    signinCompleted ({ email, registrationRequired }) {
      this.email = email
      if (registrationRequired) {
        if (this.project.registrationEnabled && this.project.form.enabled) {
          this.step = 'register'
        } else {
          this.step = 'register-disabled'
        }
      } else {
        this.showMessage(this.$t('v.signInSuccesful'))
        this.successClose()
      }
    },

    registerCompleted () {
      this.success()
      this.step = 'confirm'
    },

    ...mapActions({
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
.v-dialog .v-stepper.theme--dark, .v-dialog .v-stepper.theme--light {
  background-color: transparent;
  box-shadow: none;
}
</style>
