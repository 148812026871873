import { kebabCase, uuid, getSizeSlight, addDuration, getTheme } from '@/helpers'

function getColor (color, siteColor, siteTheme, alpha) {
  color = color || siteColor || (siteTheme ? '#000000' : '#ffffff')
  if (alpha) {
    color += Math.floor(alpha * 255).toString(16)
  }
  return color
}

class SectionTheme {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this.background = data.background
    this.text = data.text
    this.padding = data.padding || 'm'
    this.dark = data.dark // This property is required when cloning duplicating sections in Sections class
    this.theme = getTheme(data.dark)
    this.imageUrl = data.imageUrl
    this.imageSize = data.imageSize
    this.imageAlignmentHorizontal = data.imageAlignmentHorizontal
    this.imageAlignmentVertical = data.imageAlignmentVertical
    Object.freeze(this)
  }

  get style () {
    const style = {
      backgroundColor: this.background,
      color: this.text
    }

    if (!style.color) {
      if (this.theme.dark) {
        style.color = '#ffffff'
      }
      if (this.theme.light) {
        style.color = '#000000df'
      }
    }

    if (this.imageUrl) {
      style.backgroundImage = `url(${this.imageUrl})`
    }
    if (this.imageSizeStyle) {
      style.backgroundSize = this.imageSizeStyle
    }
    if (this.imageAlignmentStyle) {
      style.backgroundPosition = this.imageAlignmentStyle
    }

    return style
  }

  get paddingClass () {
    return {
      0: 'py-0',
      xs: 'py-1',
      s: 'py-8',
      m: 'py-16',
      l: 'py-24',
      xl: 'py-32'
    }[this.padding]
  }

  get imageSizeStyle () {
    if (!this.imageSize) {
      return ''
    }

    return {
      stretch: '100% 100%'
    }[this.imageSize] || this.imageSize
  }

  get imageAlignmentStyle () {
    let style = ''

    if (this.imageAlignmentHorizontal) {
      style += this.imageAlignmentHorizontal
      if (this.imageAlignmentVertical) {
        style += ' '
      }
    }

    if (this.imageAlignmentVertical) {
      style += this.imageAlignmentVertical
    }

    return style
  }

  backgroundRGBA (site, alpha) {
    return getColor(this.background, site.colors.background, site.colors.dark, alpha)
  }

  textRGBA (site, alpha) {
    return getColor(this.text, site.colors.text, site.colors.dark, alpha)
  }
}

class Section {
  constructor (data) {
    this.id = data.id || uuid()
    this.enabled = !!data.enabled
    this.protect = !!data.protect
    this.hideOnMenu = !!data.hideOnMenu
    this.title = data.title
    this._anchor = data.anchor
    this.theme = new SectionTheme(data.theme)
    this.condition = data.condition
  }

  get type () {
    return this.constructor.name
  }

  get component () {
    return `${this.type}Section`
  }

  get anchor () {
    return this._anchor || kebabCase(this.title)
  }

  get showOnMenu () {
    return !this.hideOnMenu
  }

  visible (evaluateCondition) {
    return evaluateCondition(this.condition)
  }
}

class BannerImage extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.imageUrl = data.imageUrl
    Object.freeze(this)
  }
}

class BannerSnf extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.imageUrl = data.imageUrl
    Object.freeze(this)
  }
}

class Header extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.logoUrl = data.logoUrl
    this.ctaLabel = data.ctaLabel
    this.ctaUrl = data.ctaUrl
    this.ctaRegistration = data.ctaRegistration
    Object.freeze(this)
  }
}

class Countdown {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this.enabled = data.enabled
    this.date = data.date ? new Date(data.date) : null
    Object.freeze(this)
  }
}

class Hero extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.backgroundUrl = data.backgroundUrl
    this.logoUrl = data.logoUrl
    this.logoSize = data.logoSize
    this.ctaLabel = data.ctaLabel
    this.ctaUrl = data.ctaUrl
    this.ctaRegistration = data.ctaRegistration
    this.text = data.text
    this.countdown = new Countdown(data.countdown)
    Object.freeze(this)
  }

  get logoHeight () {
    return getSizeSlight(160, this.logoSize)
  }

  get logoHeightDesktop () {
    return Math.round(this.logoHeight * 1.3)
  }
}

class Player extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.backgroundUrl = data.backgroundUrl
    this.src = data.src
    Object.freeze(this)
  }
}

class IntroductionItem {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.icon = data.icon
    this.text = data.text
    this.imageUrl = data.imageUrl
    Object.freeze(this)
  }
}

class Introduction extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.text = data.text
    this.items = (data.items || []).map(item => new IntroductionItem(item))
    Object.freeze(this)
  }
}

class Event {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.title = data.title
    this.date = data.date
    this.duration = data.duration
    this.subtitle = data.subtitle
    this.description = data.description
    this.speakers = (data.speakers || []).map(speaker => typeof speaker === 'object' ? new Speaker(speaker) : new Speaker({ name: speaker }))
    Object.freeze(this)
  }

  get endDate () {
    if (!this.date || !this.duration) {
      return null
    }
    return addDuration(this.date, this.duration)
  }
}

class Agenda extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.showDate = !!data.showDate
    this.showTime = !!data.showTime
    this.showEndTime = !!data.showEndTime
    this.events = (data.events || []).map(event => new Event(event))
    this.invertHeader = !!data.invertHeader
    this.alternateRows = !!data.alternateRows
    this.separateRows = !!data.separateRows
    this.alignTimestamp = data.alignTimestamp || 'center'
    this.alignTitle = data.alignTitle || 'left'
    Object.freeze(this)
  }

  get alignTimestampClass () {
    return `text-${this.alignTimestamp}`
  }

  get alignTitleClass () {
    return `text-${this.alignTitle}`
  }
}

class AgendaTimeline extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.showDate = !!data.showDate
    this.showTime = !!data.showTime
    this.showEndTime = !!data.showEndTime
    this.events = (data.events || []).map(event => new Event(event))
    Object.freeze(this)
  }
}

class Video {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.description = data.description
    this.title = data.title
    this.speakers = data.speakers || []
    this.vid = data.vid
    this.vimeoId = data.vimeoId
    this.thumbnailUrl = data.thumbnailUrl
    Object.freeze(this)
  }
}

class Videos extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.columns = data.columns || 2
    this.videos = (data.videos || []).map(video => new Video(video))
    Object.freeze(this)
  }

  get columnsXs () {
    return 12
  }

  get columnsSm () {
    return this.columns >= 4 ? 6 : undefined
  }

  get columnsMd () {
    switch (this.columns) {
      case 2: return 6
      case 3: return 4
      case 4: return 3
      default: return undefined
    }
  }
}

class VideosPop extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.columns = data.columns || 2
    this.videos = (data.videos || []).map(video => new Video(video))
    Object.freeze(this)
  }

  get columnsXs () {
    return 12
  }

  get columnsSm () {
    return this.columns >= 4 ? 6 : undefined
  }

  get columnsMd () {
    switch (this.columns) {
      case 2: return 6
      case 3: return 4
      case 4: return 3
      default: return undefined
    }
  }
}

class Speaker {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.name = data.name
    this.title = data.title
    this.biography = data.biography
    this.photoUrl = data.photoUrl
    Object.freeze(this)
  }

  get id () {
    return this.name ? this.name.replace(/\s/g, '-') : null
  }
}

class Speakers extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.squarePhotos = data.squarePhotos
    this.singleColumn = data.singleColumn
    this.zigzagRows = data.zigzagRows
    this.speakers = (data.speakers || []).map(speaker => new Speaker(speaker))
    Object.freeze(this)
  }
}

class SpeakersPop extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.speakers = (data.speakers || []).map(speaker => new Speaker(speaker))
    Object.freeze(this)
  }
}

class Link {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.text = data.text
    this.url = data.url
    Object.freeze(this)
  }
}

class Notice extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.content = data.content
    Object.freeze(this)
  }
}

class Footer extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.logo1Url = data.logo1Url
    this.logo2Url = data.logo2Url
    this.copyright = data.copyright
    this.certificate = data.certificate
    this.lastModification = data.lastModification
    this.links = (data.links || []).map(link => new Link(link))
    Object.freeze(this)
  }
}

class NetworkingCard {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.title = data.title
    this.text = data.text
    this.imageUrl = data.imageUrl
    this.ctaLabel = data.ctaLabel
    this.ctaUrl = data.ctaUrl
    Object.freeze(this)
  }
}

class Networking extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.logoUrl = data.logoUrl
    this.cards = (data.cards || []).map(card => new NetworkingCard(card))
    Object.freeze(this)
  }
}

class RichText extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.content = data.content
    Object.freeze(this)
  }
}

class Html extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.content = data.content
    Object.freeze(this)
  }
}

class Sponsor {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.logoUrl = data.logoUrl
    this.url = data.url
    Object.freeze(this)
  }
}

class Sponsors extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.logoSize = data.logoSize
    this.sponsors = (data.sponsors || []).map(sponsor => new Sponsor(sponsor))
    Object.freeze(this)
  }

  get logoHeight () {
    return getSizeSlight(80, this.logoSize)
  }
}

class CarouselItem {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.imageUrl = data.imageUrl
    this.ctaUrl = data.ctaUrl
    Object.freeze(this)
  }
}

class Carousel extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)
    this.title = data.title
    this.itemSize = data.itemSize
    this.items = (data.items || []).map(item => new CarouselItem(item))
    Object.freeze(this)
  }

  get itemHeight () {
    return getSizeSlight(160, this.itemSize)
  }

  get itemWidth () {
    return getSizeSlight(600, this.itemSize)
  }
}

class SessionPlayer extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.sessionId = data.sessionId
    this.inPerson = !!data.inPerson

    Object.freeze(this)
  }
}

class Q extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.title = data.title
    this.qId = data.qId
    this.hideReplied = !!data.hideReplied

    Object.freeze(this)
  }

  visible (evaluateCondition) {
    if (!this.qId) {
      return false
    }

    if (this.hideReplied) {
      if (evaluateCondition(`!loaded || qs has "${this.qId}"`)) {
        return false
      }
    }

    return super.visible(evaluateCondition)
  }
}

class Diploma extends Section {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.title = data.title
    this.diplomaId = data.diplomaId

    Object.freeze(this)
  }

  visible (evaluateCondition) {
    if (!this.diplomaId) {
      return false
    }

    // Testing without diplomaId generated by poll reply.
    // ToDo: to eliminate or to uncomment block
    // if (evaluateCondition(`!diplomas has "${this.diplomaId}"`)) {
    //   return false
    // }

    return super.visible(evaluateCondition)
  }
}

const SECTIONS = {}
;[
  BannerImage,
  BannerSnf,
  Header,
  Hero,
  Player,
  Introduction,
  Agenda,
  AgendaTimeline,
  Videos,
  VideosPop,
  Speakers,
  SpeakersPop,
  Notice,
  Footer,
  Networking,
  RichText,
  Html,
  Sponsors,
  Carousel,
  SessionPlayer,
  Q,
  Diploma
].forEach((section) => {
  SECTIONS[section.name] = section
})

function createSection (section) {
  if (!section.type) {
    // eslint-disable-next-line no-console
    console.error(`Missing section type for section: ${section}`)
    return
  }

  const SectionClass = SECTIONS[section.type]

  if (!SectionClass) {
    // eslint-disable-next-line no-console
    console.error(`Unknown section type: ${section.type}`)
    return
  }

  return new SectionClass(section)
}

export class Sections {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this.main = (data.main || []).map(createSection).filter(section => section)
    this.header = (data.header || []).map(createSection).filter(section => section)
    this.footer = (data.footer || []).map(createSection).filter(section => section)

    this.all = [...this.header, ...this.main, ...this.footer]

    this.sessionIds = this.all
      .filter(section => section.type === 'SessionPlayer' && section.sessionId)
      .map(section => section.sessionId)

    Object.freeze(this)
  }
}
