export default class ProjectForm {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this._id = data.id

    this.enabled = !!data.enabled
    this.small = !!data.small
    this.solo = !!data.solo
    this.outlined = !!data.outlined
    this.fieldValues = !!data.fieldValues

    this.language = data.language

    this.message = data.message

    this.signInDescription = data.signInDescription
    this.passDescription = data.passDescription
    this.registerDescription = data.registerDescription
    this.confirmationDescription = data.confirmationDescription
    this.registrationDisabledDescription = data.registrationDisabledDescription
    this.resetPasswordDescription = data.resetPasswordDescription
    this.checkPasswordEmailDescription = data.checkPasswordEmailDescription

    this.customCss = data.customCss

    this.fields = data.fields || []

    Object.freeze(this)
  }

  get passwordField () {
    return this.fields.find(field => field.password)
  }
}
