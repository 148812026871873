import { conditionGrammar, parseCondition, ParsingError } from './ConditionGrammar'
import { get } from '@/helpers'

const conditionSemantics = conditionGrammar.createSemantics()

conditionSemantics.addOperation('evaluate(data)', {
  Expr (e) {
    return e.evaluate(this.args.data)
  },

  CompExpr_eq (l, _, r) {
    // eslint-disable-next-line eqeqeq
    return l.evaluate(this.args.data) == r.evaluate(this.args.data)
  },
  CompExpr_gt (l, _, r) {
    return l.evaluate(this.args.data) > r.evaluate(this.args.data)
  },
  CompExpr_lt (l, _, r) {
    return l.evaluate(this.args.data) < r.evaluate(this.args.data)
  },
  CompExpr_gte (l, _, r) {
    return l.evaluate(this.args.data) >= r.evaluate(this.args.data)
  },
  CompExpr_lte (l, _, r) {
    return l.evaluate(this.args.data) <= r.evaluate(this.args.data)
  },

  OrExpr (e) {
    return e.evaluate(this.args.data)
  },
  OrExpr_or (l, _, r) {
    return l.evaluate(this.args.data) || r.evaluate(this.args.data)
  },

  AndExpr (e) {
    return e.evaluate(this.args.data)
  },
  AndExpr_and (l, _, r) {
    return l.evaluate(this.args.data) && r.evaluate(this.args.data)
  },

  AddExpr (e) {
    return e.evaluate(this.args.data)
  },
  AddExpr_add (l, _, r) {
    return l.evaluate(this.args.data) + r.evaluate(this.args.data)
  },
  AddExpr_substract (l, _, r) {
    return l.evaluate(this.args.data) - r.evaluate(this.args.data)
  },

  MulExpr (e) {
    return e.evaluate(this.args.data)
  },
  MulExpr_multiply (l, _, r) {
    return l.evaluate(this.args.data) * r.evaluate(this.args.data)
  },
  MulExpr_divide (l, _, r) {
    return l.evaluate(this.args.data) / r.evaluate(this.args.data)
  },

  PriExpr (e) {
    return e.evaluate(this.args.data)
  },
  PriExpr_paren (_l, e, _r) {
    return e.evaluate(this.args.data)
  },
  PriExpr_negation (_, e) {
    return !e.evaluate(this.args.data)
  },
  PriExpr_inversion (_, e) {
    return -e.evaluate(this.args.data)
  },
  PriExpr_has (l, _, r) {
    const le = l.evaluate(this.args.data)
    const re = r.evaluate(this.args.data)
    return le && ((Array.isArray(le) && le.includes(re)) || (typeof le === 'object' && !!le[re]))
  },

  FunExpr (e) {
    return e.evaluate(this.args.data)
  },
  FunExpr_number (_f, _lp, v, _rp) {
    return Number(v.evaluate(this.args.data))
  },
  FunExpr_default (_f, _lp, v, _c, d, _rp) {
    const value = v.evaluate(this.args.data)
    return value != null ? value : d.evaluate(this.args.data)
  },
  FunExpr_min (_f, _lp, l, _c, r, _rp) {
    return Math.min(l.evaluate(this.args.data), r.evaluate(this.args.data))
  },
  FunExpr_max (_f, _lp, l, _c, r, _rp) {
    return Math.max(l.evaluate(this.args.data), r.evaluate(this.args.data))
  },

  path (_l, _a, _d, _ar) {
    return get(this.args.data, this.sourceString)
  },

  const_true (_) {
    return true
  },
  const_false (_) {
    return false
  },

  string (_qo, _s, _qc) {
    return this.sourceString.substring(1, this.sourceString.length - 1)
  },

  number_whole (_) {
    return parseInt(this.sourceString)
  },
  number_fraction (_e, _d, _f) {
    return parseFloat(this.sourceString)
  },
  number_infinity (_) {
    return Infinity
  }
})

export function evaluateCondition (condition, data) {
  if (!condition || condition === TRUE_CONDITION) {
    return true
  } else if (condition === FALSE_CONDITION) {
    return false
  }

  if (!data) {
    data = {}
  }

  const result = conditionSemantics(condition).evaluate(data)
  return result
}

export const TRUE_CONDITION = 'true'
export const FALSE_CONDITION = 'false'

const conditionCache = {}

export function parseEvaluateCondition (condition, data) {
  if (!condition) {
    return true
  }

  if (!conditionCache[condition]) {
    try {
      conditionCache[condition] = parseCondition(condition)
    } catch (error) {
      if (error instanceof ParsingError) {
        // eslint-disable-next-line no-console
        console.error(`Error parsing condition: ${error.message}, evaluating to false`)
        conditionCache[condition] = FALSE_CONDITION
      } else {
        throw error
      }
    }
  }

  return evaluateCondition(conditionCache[condition], data)
}
