import Countries from './Countries'
import Specialties from './Specialties'
import FsSectors from './FsSectors'
import MxStates from './MxStates'
import Genders from './Genders'
import { isBlank, blankToNull, isAlphaNumeric } from '@/helpers'

const SIZES = {
  xs: 2,
  s: 4,
  m: 6,
  l: 8,
  xl: 12
}

export class Field {
  constructor (data) {
    if (isBlank(data.name)) {
      throw new Error('Missing field name')
    }
    if (isBlank(data.label)) {
      throw new Error('Missing field label')
    }

    this._name = data.name
    this._label = data.label
    this._hint = blankToNull(data.hint)
    this._required = !!data.required
    this._rules = data.rules || []
    this._size = Object.keys(SIZES).includes(data.size) ? data.size : 'xl'
    this._dense = !!data.dense
    this._solo = !!data.solo

    this._condition = blankToNull(data.condition)
  }

  get name () {
    return this._name
  }

  get label () {
    return this._label
  }

  get hint () {
    return this._hint
  }

  get required () {
    return this._required
  }

  get rules () {
    return this._rules
  }

  get size () {
    return this._size
  }

  get cols () {
    return SIZES[this.size]
  }

  get dense () {
    return this._dense
  }

  get solo () {
    return this._solo
  }

  get condition () {
    return this._condition
  }
}

function ruleToFunction (rule) {
  if (rule.minlength) {
    return v => !v || v.length >= rule.minlength || rule.message || `Minimum length ${rule.minlength} characters`
  }

  if (rule.maxlength) {
    return v => !v || v.length <= rule.maxlength || rule.message || `Maximum length ${rule.maxlength} characters`
  }

  if (rule.pattern) {
    const re = new RegExp(rule.pattern)
    return (v) => {
      if (!v) {
        return true
      }

      const m = re.exec(v)
      if (m && m[0].length === v.length) {
        return true
      }

      return rule.message || `Required format: '${rule.pattern}'`
    }
  }

  // eslint-disable-next-line no-console
  console.warn(`Unknown field rule: ${JSON.stringify(rule)}`)
  return null
}

export class TextField extends Field {
  constructor (data) {
    const rules = (data.rules || []).map(rule => typeof rule !== 'function' ? ruleToFunction(rule) : rule).filter(rule => rule)
    super({ ...data, rules })
  }

  get component () {
    return 'TextField'
  }
}

export class CheckField extends Field {
  get component () {
    return 'CheckField'
  }
}

export class SelectField extends Field {
  constructor (data) {
    super(data)

    if (!data.options) {
      throw new Error('Missing select field options')
    }

    this._options = data.fieldValues ? data.options : data.options.map(option => option.label ? option.label : option)
  }

  get component () {
    return 'SelectField'
  }

  get options () {
    return this._options
  }
}

export class RadioField extends Field {
  constructor (data) {
    const rules = (data.rules || []).map(rule => typeof rule !== 'function' ? ruleToFunction(rule) : rule).filter(rule => rule)
    super({ ...data, rules })

    if (!data.options) {
      throw new Error('Missing radio field options')
    }

    this._options = data.fieldValues ? data.options : data.options.map(option => option.label ? option.label : option)
    this._row = data.row
  }

  get component () {
    return 'RadioField'
  }

  get options () {
    return this._options
  }

  get row () {
    return this._row
  }
}

export class CountryField extends SelectField {
  constructor (data) {
    const options = Countries.all().map(country => ({ label: country.name, value: country.code }))
    super({ ...data, options, fieldValues: true })
  }
}

export class SpecialtyField extends SelectField {
  constructor (data) {
    const options = Specialties.all().map(specialty => ({ label: specialty.name, value: specialty.id }))
    super({ ...data, options })
  }
}

export class FsSectorField extends SelectField {
  constructor (data) {
    super({ ...data, options: FsSectors.all() })
  }
}

export class MxStateField extends SelectField {
  constructor (data) {
    super({ ...data, options: MxStates.all() })
  }
}

export class GenderField extends RadioField {
  constructor (data) {
    const options = Genders.all().map(gender => ({ label: gender.name, value: gender.id }))
    super({ ...data, options })
  }
}

export class PhoneField extends Field {
  constructor (data) {
    super(data)

    this._defaultCountry = data.defaultCountry || 'MX'
  }

  get component () {
    return 'PhoneField'
  }

  get defaultCountry () {
    return this._defaultCountry
  }
}

export class MedicalLicenceField extends TextField {
  constructor (data) {
    super({
      ...data,
      rules: [
        ...(data.rules || []),
        v => !v || isAlphaNumeric(v) || 'Solo caracteres alfanuméricos',
        v => !v || v.length >= 6 || 'Al menos 6 caracteres',
        v => !v || v.length <= 16 || 'Como máximo 16 caracteres'
      ]
    })
  }
}

const MEDICAL_LICENCE_BLACKLIST = [
  /tram/i, /pend/i, /ning/i, /12345/,
  /^0000+$/, /^11111+$/, /^22222+$/, /^33333+$/, /^44444+$/, /^55555+$/, /^66666+$/, /^77777+$/, /^88888+$/, /^99999+$/
]

function trivialFakeMedicalLicense (licence) {
  // Remove accents / diacritics, source: https://stackoverflow.com/a/37511463/4619705
  licence = licence.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

  return MEDICAL_LICENCE_BLACKLIST.some(exception => exception.test(licence))
}

export class MedicalLicenceSnfField extends TextField {
  constructor (data) {
    super({
      ...data,
      rules: [
        ...(data.rules || []),
        v => !v || (v.length >= 5 && v.length <= 10 && isAlphaNumeric(v) && !trivialFakeMedicalLicense(v)) || 'Por favor escriba su Cédula Profesional correctamente'
      ]
    })
  }
}

export class PasswordField extends TextField {
  constructor (data) {
    super(data)

    this._confirmLabel = blankToNull(data.confirmLabel)
  }

  get component () {
    return 'PasswordField'
  }

  get password () {
    return true
  }

  get confirmLabel () {
    return this._confirmLabel
  }
}
