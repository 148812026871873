import { Sections } from './sections'
import Language from './Language'
import { getTheme } from '@/helpers'

class SiteVariant {
  constructor (data = {}) {
    this.id = data.id
    this.language = data.language ? Language.fromString(data.language) : null
    this.url = data.url
  }
}

class SiteColors {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this.dark = data.dark

    this.background = data.background
    this.text = data.text

    this.primary = data.primary
    this.secondary = data.secondary
    this.accent = data.accent

    this.notificationTheme = getTheme(data.notificationDark)

    Object.freeze(this)
  }
}

class Disclaimer {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.enabled = !!data.enabled
    this.title = data.title
    this.content = data.content
    this.ctaLabel = data.ctaLabel
  }
}

function extractFontFamilyName (fontFamily) {
  return fontFamily != null ? fontFamily.split(':')[0] : null
}

export default class Site {
  constructor (data = {}) {
    this.id = data.id
    this.zone = data.zone
    this.site = data.site
    this.vanityUrl = data.vanityUrl
    this.title = data.title || 'Website'
    this.sections = new Sections(data.sections)
    this.colors = new SiteColors(data.colors)
    this.slideIn = !!data.slideIn
    this.projectId = data.projectId
    this.auth = data.auth
    this.embeddedAuth = !!data.embeddedAuth
    this.nativeAuth = !!data.nativeAuth
    this.userMenu = data.userMenu
    this.googlePropertyId = data.tracking ? data.googlePropertyId : null
    this.googleAdsPropertyId = data.tracking ? data.googleAdsPropertyId : null
    this.googleAdsLabel = data.tracking ? data.googleAdsLabel : null
    this.fbPixelId = data.tracking ? data.fbPixelId : null
    this.lnPixelId = data.tracking ? data.lnPixelId : null
    this.twPixelId = data.tracking ? data.twPixelId : null
    this.customCss = data.customCss
    this.faviconUrl = data.faviconUrl
    this.fontFamily = data.fontFamily
    this.headingFontFamily = data.headingFontFamily || this.fontFamily

    this.language = data.language ? Language.fromString(data.language) : null
    this.variants = (data.variants && data.variants.length > 0) ? data.variants.map(variant => new SiteVariant(variant)) : null

    this.redirectUrl = data.redirectUrl || null

    this.disclaimer = new Disclaimer(data.disclaimer)

    Object.freeze(this)
  }

  get fontFamilyName () {
    return extractFontFamilyName(this.fontFamily)
  }

  get headingFontFamilyName () {
    return extractFontFamilyName(this.headingFontFamily)
  }
}
