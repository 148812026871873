<template>
  <v-menu offset-y :dark="dark" :light="light">
    <template #activator="{ on }">
      <span>
        <v-btn v-if="$vuetify.breakpoint.mdAndUp" text class="px-5 py-3 title font-size-1 text-none ml-2" v-on="on">
          {{ language.localName }}
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </v-btn>
        <navigation-item v-else menu v-on="on">
          {{ language.localName }}
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </navigation-item>
      </span>
    </template>
    <v-list>
      <v-list-item v-for="variant in variants" :key="variant.id" @click="selectVariant(variant)">
        <v-list-item-title>{{ variant.language.localName }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex'
import NavigationItem from './NavigationItem'

export default {
  components: {
    NavigationItem
  },

  props: {
    language: {
      type: Object,
      required: true
    },

    variants: {
      type: Array,
      required: true
    },

    dark: {
      type: Boolean,
      default: undefined
    },

    light: {
      type: Boolean,
      default: undefined
    }
  },

  methods: {
    ...mapActions({
      selectVariant: 'sites/selectVariant'
    })
  }
}
</script>
