<template>
  <v-form ref="form" lazy-validation @submit.prevent="onReset">
    <v-card-text>
      <v-row>
        <v-col v-if="project.form.resetPasswordDescription" cols="12" v-html="project.form.resetPasswordDescription" />
        <v-col v-else cols="12">
          {{ $t('v.resetPassword') }}
          <p class="mt-2">
            {{ $t('v.resetPasswordDescriptionSn') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" class="py-2 grey--text">
          <v-icon aria-hidden="false">
            mdi-account
          </v-icon>
          {{ email }}
        </v-col>
      </v-row>

      <v-row align="center" class="pt-11">
        <v-col cols="auto">
          <v-btn :small="project.form.small" nuxt text @click="previous">
            {{ $t('v.back') }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn :small="project.form.small" :loading="loading" color="accent" class="px-11" type="submit">
            {{ $t('v.resetMyPassword') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepMixin from './StepMixin'

export default {
  mixins: [
    StepMixin
  ],

  props: {
    email: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters({
      project: 'projects/project'
    })
  },

  methods: {
    async onReset () {
      try {
        this.loading = true

        await this.wdotAdd({ event: 'resetPassword', email: this.email })

        await this.resetPasswordSn({ email: this.email })

        this.next()
      } catch (error) {
        if (error.code) {
          if (error.code === 'functions/unknown') {
            this.showSnError(error.message)
          } else if (error.code === 'functions/deadline-exceeded') {
            this.showError(this.$t('v.deadlineExceededError'))
          } else {
            this.showError(this.$t('v.resetPasswordError'))
          }
        } else {
          this.showError(this.$t('v.unknownError'))
        }
        this.$logError(error)
      } finally {
        this.loading = false
      }
    },

    ...mapActions({
      resetPasswordSn: 'auth/resetPasswordSn',
      showSnError: 'auth/showSnError',
      wdotAdd: 'wdots/add',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
</style>
