import Vue from 'vue'
import { isIE } from '@/helpers'

if (isIE()) {
  window.location = 'old.html'
}

Vue.config.productionTip = false

export default async ({ store }) => {
  await store.dispatch('init')
}
