import { getFirestore, collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, signInWithCustomToken, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth'

import { keysRemoveTrailingUnderscore, omit, getPrefixedEmail, removeEmailPrefix } from '@/helpers'
import User from '@/models/User'
import ProjectUser from '@/models/auth/ProjectUser'
import ProjectUserExists from '@/models/auth/ProjectUserExists'
import SnUserExists from '@/models/auth/SnUserExists'
import ProjectForm from '@/models/auth/ProjectForm'
import { TextField, CheckField, SelectField, RadioField, PhoneField, CountryField, SpecialtyField, FsSectorField, MxStateField, GenderField, MedicalLicenceField, MedicalLicenceSnfField, PasswordField } from '@/models/auth/Fields'

export const state = () => ({
  user: null,

  projectUser: null,

  projectUserExists: null,

  projectForms: null,

  loginDialog: false,

  loginNativeDialog: false,

  prefillEmail: null,

  passwordReset: null
})

export const getters = {
  user: state => state.user,

  projectUser: state => state.projectUser,

  projectUserExists: state => state.projectUserExists,

  signedIn: state => state.user && state.projectUser && state.user.uid === state.projectUser.uid,

  loginDialog: state => state.loginDialog,

  loginNativeDialog: state => state.loginNativeDialog,

  workflow: (state, getters, rootState, rootGetters) => rootState.projects.project ? rootState.projects.project.authWorkflow : null,

  embeddedAuth: (state, getters, rootState, rootGetters) => rootGetters['sites/site'] ? rootGetters['sites/site'].embeddedAuth : false,

  nativeAuth: (state, getters, rootState, rootGetters) => rootGetters['sites/site'] ? rootGetters['sites/site'].nativeAuth : false,

  passwordReset: state => !!state.passwordReset,

  authURL: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/site']
    if (!site) {
      return null
    }

    let url = '/auth/signin'

    const referrer = `${window.location.pathname}${window.location.search}`
    url += `?referrer=${encodeURIComponent(referrer)}`

    if (site.projectId) {
      url += `&projectId=${site.projectId}`
    }

    if (rootGetters.locale) {
      url += `&locale=${rootGetters.locale}`
    }

    if (getters.embeddedAuth) {
      url += '&embedded=1'
    }

    return url
  }
}

export const mutations = {
  setUser (state, user) {
    state.user = user
  },

  setProjectUser (state, projectUser) {
    state.projectUser = projectUser
  },

  setProjectUserExists (state, projectUserExists) {
    state.projectUserExists = projectUserExists
  },

  setProjectForms (state, projectForms) {
    state.projectForms = projectForms
  },

  setLoginDialog (state, loginDialog) {
    state.loginDialog = loginDialog
  },

  setLoginNativeDialog (state, loginDialog) {
    state.loginNativeDialog = loginDialog
  },

  setPrefillEmail (state, email) {
    state.prefillEmail = email
  },

  setPasswordReset (state, reset) {
    state.passwordReset = reset
  }
}

function dataToUser (data) {
  return new User(data)
}

function dataToProjectUser (data) {
  return new ProjectUser(data)
}

function projectUserToData (projectUser) {
  return omit(keysRemoveTrailingUnderscore(projectUser), 'id')
}

function dataToProjectUserExists (data) {
  return new ProjectUserExists(data)
}

function dataToSnUserExists (data) {
  return new SnUserExists(data)
}

function dataToProjectForm (id, data) {
  if (!data) {
    return null
  }
  const fields = (data.fields || [])
    .map(fieldData => ({ dense: data.small, solo: data.solo, fieldValues: data.fieldValues, ...fieldData }))
    .map(dataToField)
    .filter(field => field)
  return new ProjectForm({ ...data, fields })
}

function dataToField (data) {
  switch (data.type) {
    case 'text':
      return new TextField(data)
    case 'check':
      return new CheckField(data)
    case 'select':
      return new SelectField(data)
    case 'radio':
      return new RadioField(data)
    case 'country':
      return new CountryField(data)
    case 'specialty':
      return new SpecialtyField(data)
    case 'fssector':
      return new FsSectorField(data)
    case 'mxstate':
      return new MxStateField(data)
    case 'gender':
      return new GenderField(data)
    case 'phone':
      return new PhoneField(data)
    case 'medicallicence':
      return new MedicalLicenceField(data)
    case 'medicallicencesnf':
      return new MedicalLicenceSnfField(data)
    case 'password':
      return new PasswordField(data)
    default:
      // eslint-disable-next-line no-console
      console.warn(`Ignoring unknown field type: ${data.type}`)
  }
}

function selectForm (forms, locale, defaultLocale) {
  if (forms.length === 0) {
    return null
  }
  if (forms.length === 1) {
    return forms[0]
  }
  const localeForm = forms.find(form => form.language === locale)
  if (localeForm) {
    return localeForm
  }
  const defaultForm = forms.find(form => form.language === defaultLocale)
  if (defaultForm) {
    return defaultForm
  }
  throw new Error('Missing registration form')
}

function handlePasswordResetError (error) {
  if (error.code === 'auth/expired-action-code') {
    throw new Error('v.resetPasswordErrorExpired')
  } else if (error.code === 'auth/invalid-action-code') {
    throw new Error('v.resetPasswordErrorInvalid')
  } else if (error.code === 'auth/user-disabled') {
    throw new Error('v.resetPasswordErrorUserDisabled')
  } else if (error.code === 'auth/user-not-found') {
    throw new Error('v.resetPasswordErrorUserNotFound')
  } else if (error.code === 'auth/weak-password') {
    throw new Error('v.resetPasswordErrorWeakPassword')
  } else {
    throw new Error('v.resetPasswordError')
  }
}

export const actions = {
  init ({ commit, getters, dispatch }) {
    return new Promise((resolve, reject) => {
      try {
        onAuthStateChanged(getAuth(), (userData) => {
          try {
            const user = userData ? dataToUser(userData) : null
            commit('setUser', user)

            if (!getters.signedIn && getters.projectUser) {
              dispatch('signOutProjectUser')
            }

            resolve(user)
          } catch (error) {
            reject(error)
          }
        })
      } catch (error) {
        reject(error)
      }
    })
  },

  async autoRegisterDialog ({ dispatch, commit, getters }) {
    if (window.location.hash === '#registro') {
      history.pushState('', document.title, window.location.pathname + window.location.search)
      if (!getters.signedIn) {
        await dispatch('externalSignIn')
      }
      return
    }

    const params = new URL(window.location).searchParams
    const initialParams = params.toString()

    let email = params.get('e')
    if (email) {
      params.delete('e')
    }

    const reset = params.has('reset')
    if (reset) {
      params.delete('reset')
    }
    const code = params.get('code')
    if (code) {
      params.delete('code')
    }

    const finalParams = params.toString()
    if (finalParams !== initialParams) {
      let url = window.location.pathname
      if (finalParams.length > 0) {
        url += `?${finalParams}`
      }
      history.pushState('', document.title, url)
    }

    if (email && !getters.signedIn) {
      try {
        email = atob(email)
        commit('setPrefillEmail', email)
        await dispatch('externalSignIn')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    if (reset && code) {
      try {
        const email = await dispatch('verifyPasswordResetCode', { code })
        commit('setPasswordReset', { code, email })
        await dispatch('externalSignIn')
      } catch (error) {
        await dispatch('notification/showError', this.$t(error.message), { root: true })
      }
    }
  },

  usePrefillEmail ({ commit, state }) {
    const prefillEmail = state.prefillEmail
    if (prefillEmail) {
      commit('setPrefillEmail', null)
    }
    return prefillEmail
  },

  usePasswordReset ({ commit, state }) {
    const passwordReset = state.passwordReset
    if (passwordReset) {
      commit('setPasswordReset', null)
    }
    return passwordReset
  },

  async fetchProjectForms ({ commit, dispatch }, projectId) {
    const snapshot = await getDocs(query(collection(getFirestore(), 'projects', projectId, 'forms'), where('enabled', '==', true)))

    const projectForms = snapshot.docs.map(doc => dataToProjectForm(doc.id, doc.data()))

    commit('setProjectForms', projectForms)

    return projectForms
  },

  async getLocalizedProjectForm ({ state, dispatch, rootGetters }, projectId) {
    await dispatch('fetchProjectForms', projectId)
    const form = selectForm(state.projectForms, rootGetters.locale, rootGetters.defaultLocale)
    return form
  },

  externalSignIn ({ state, getters, dispatch, commit, rootGetters }) {
    if (getters.nativeAuth) {
      commit('setLoginNativeDialog', true)
    } else if (getters.embeddedAuth) {
      commit('setLoginDialog', true)
    } else {
      window.location.href = getters.authURL
    }
  },

  hideLoginDialog ({ commit }) {
    commit('setLoginDialog', false)
  },

  hideLoginNativeDialog ({ commit }) {
    commit('setLoginNativeDialog', false)
  },

  async signIn ({ dispatch, rootGetters }, url) {
    await dispatch('wdots/add', { event: 'signIn' }, { root: true })
    const site = rootGetters['sites/site']
    if (!site) {
      return
    }
    dispatch('analytics/signInOpen', site.projectId, { root: true })

    if (site.auth) {
      await dispatch('externalSignIn')
    } else {
      await dispatch('showRegistrationDialog', url)
    }
  },

  async signInNativeWithPrefix ({ rootGetters }, { email, password }) {
    const project = rootGetters['projects/project']
    const userCredential = await signInWithEmailAndPassword(getAuth(), getPrefixedEmail(email, project), password)
    return userCredential
  },

  async signInNative ({ commit, dispatch, getters }, { email, password }) {
    if (!password) {
      password = email
    }
    try {
      await dispatch('wdots/add', { event: 'signIn' }, { root: true })

      await dispatch('signOut')

      let userCredential
      if (getters.workflow.prefix) {
        userCredential = await dispatch('signInNativeWithPrefix', { email, password })
      } else {
        userCredential = await signInWithEmailAndPassword(getAuth(), email, password)
      }

      const user = dataToUser(userCredential.user)
      commit('setUser', user)

      return user
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        return null
      }
      if (error.code === 'auth/wrong-password') {
        throw new Error('v.passwordInvalid')
      }
      await dispatch('wdots/add', { event: 'signInError', email, error: { code: error.code } }, { root: true })
      if (error.code === 'auth/invalid-email') {
        // eslint-disable-next-line no-console
        console.error(`Invalid email: ${email}`)
        throw new Error('v.emailInvalid')
      }
      throw error
    }
  },

  async createUserNativeWithPrefix ({ rootGetters }, { email, password }) {
    const project = rootGetters['projects/project']
    const userCredential = await createUserWithEmailAndPassword(getAuth(), getPrefixedEmail(email, project), password)
    return userCredential
  },

  async createUserNative ({ dispatch, commit, getters }, { email, password }) {
    if (!password) {
      password = email
    }
    try {
      await dispatch('wdots/add', { event: 'createUser', email }, { root: true })

      let userCredential
      if (getters.workflow.prefix) {
        userCredential = await dispatch('createUserNativeWithPrefix', { email, password })
      } else {
        userCredential = await createUserWithEmailAndPassword(getAuth(), email, password)
      }

      const user = dataToUser(userCredential.user)
      commit('setUser', user)

      return user
    } catch (error) {
      await dispatch('wdots/add', { event: 'registerError', email, error: { code: error.code } }, { root: true })
      if (error.code === 'auth/invalid-email') {
        // eslint-disable-next-line no-console
        console.error(`Invalid email: ${email}`)
        throw new Error('v.emailInvalid')
      }
      throw error
    }
  },

  async resetPasswordNativeWithPrefix ({ _ }, { email, project, site, defaultOrigin }) {
    await httpsCallable(getFunctions(), 'sendPasswordResetEmail')({ projectId: project.id, siteId: site.id, defaultOrigin, email: getPrefixedEmail(email, project) })
  },

  async resetPasswordNative ({ getters, dispatch, rootGetters }, { email }) {
    const project = rootGetters['projects/project']
    const site = rootGetters['sites/site']
    const defaultOrigin = rootGetters['sites/defaultOrigin']

    if (getters.workflow.prefix) {
      await dispatch('resetPasswordNativeWithPrefix', { email, project, site, defaultOrigin })
    } else {
      await httpsCallable(getFunctions(), 'sendPasswordResetEmail')({ projectId: project.id, siteId: site.id, defaultOrigin, email })
    }
  },

  async verifyPasswordResetCode ({ dispatch }, { code }) {
    try {
      const email = await verifyPasswordResetCode(getAuth(), code)
      return removeEmailPrefix(email)
    } catch (error) {
      handlePasswordResetError(error)
    }
  },

  async confirmPasswordReset ({ dispatch }, { code, password }) {
    try {
      await confirmPasswordReset(getAuth(), code, password)
    } catch (error) {
      handlePasswordResetError(error)
    }
  },

  async initProjectUser ({ commit, state, dispatch }) {
    const user = state.user

    if (!user) {
      commit('setProjectUser', null)
      return
    }

    const projectUserExists = await dispatch('checkProjectUserExists', user.email)

    const projectUser = projectUserExists && projectUserExists.exists ? dataToProjectUser(projectUserExists) : null

    commit('setProjectUser', projectUser)

    dispatch('userdots/initUserDots', null, { root: true })
  },

  async checkProjectUserExists ({ commit, state, rootState }, email) {
    const projectId = rootState.sites.site ? rootState.sites.site.projectId : null

    if (!projectId || !email) {
      return null
    }

    const result = await httpsCallable(getFunctions(), 'projectUserExists')({ projectId, email })

    const projectUserExists = dataToProjectUserExists(result.data.message)

    commit('setProjectUserExists', projectUserExists)

    return projectUserExists
  },

  async registerProjectUser ({ state, dispatch, rootState, rootGetters }, projectUserCandidate) {
    const projectUser = new ProjectUser(projectUserCandidate)

    let user = state.user
    if (!user || user.email !== projectUser.email) {
      user = await dispatch('createUserNative', { email: projectUser.email, password: projectUser.password })
    }
    projectUser.uid = user.uid

    projectUser.meta = { ...(projectUserCandidate.meta || {}), ...rootGetters.query }
    projectUser.locale = rootGetters.locale

    const projectId = rootState.projects.project.id

    const data = { ...projectUserToData(projectUser), created: serverTimestamp() }

    await addDoc(collection(getFirestore(), 'projects', projectId, 'users'), data)

    await dispatch('checkProjectUserExists', projectUser.email)

    await dispatch('wdots/add', { event: 'registerProjectUser', projectUser: omit(projectUserToData(projectUser), 'created') }, { root: true })
    dispatch('analytics/successfulRegistration', null, { root: true })
  },

  async linkProjectUser ({ state, rootState, dispatch }, email) {
    const projectId = rootState.sites.site ? rootState.sites.site.projectId : null

    if (!projectId || !email) {
      return null
    }

    if (!state.user) {
      await dispatch('createUserNative', { email })
    }

    await httpsCallable(getFunctions(), 'linkProjectUser')({ projectId, email })
  },

  async userRegistrationRequired ({ state, dispatch, rootState }, email) {
    // check if project user already exists
    const projectUserExists = await dispatch('checkProjectUserExists', email)
    if (projectUserExists && projectUserExists.exists) {
      /* if (!state.user) {
        throw new Error('Internal error: project user does not exist in auth DB. This is a known issue if users were imported.')
      } */

      if (!projectUserExists.linked) {
        await dispatch('linkProjectUser', email)
      }
      return false
    }

    const project = rootState.projects.project

    if (project.registrationEnabled && project.form.enabled) {
      // if form has no fields, register immediately
      if (project.form.fields.length === 0) {
        await dispatch('registerProjectUser', { email })
        return false
      }
    }

    // if not user or user not in projects users, requires manual registration
    return true
  },

  async signOut ({ commit, dispatch }) {
    await dispatch('wdots/add', { event: 'signOutUser' }, { root: true })
    await signOut(getAuth())
    await dispatch('signOutProjectUser')
  },

  async signOutProjectUser ({ commit, dispatch }) {
    commit('setProjectUser', null)
    commit('setProjectUserExists', null)
    await dispatch('userdots/resetUserDots', null, { root: true })
    await dispatch('sites/refreshSite', null, { root: true })
  },

  async signInSn ({ commit, dispatch, rootState }, { email, password }) {
    try {
      const project = rootState.projects.project

      const result = await httpsCallable(getFunctions(), 'snSignIn')({ email, password, projectId: project.id })

      const token = result.data.token
      const userCandidate = result.data.user

      await dispatch('signOut')

      const userCredential = await signInWithCustomToken(getAuth(), token)
      const user = dataToUser(userCredential.user)
      commit('setUser', user)

      const projectUserExists = await dispatch('checkProjectUserExists', email)
      if (projectUserExists && !projectUserExists.exists && project.registrationEnabled) {
        await dispatch('registerProjectUser', userCandidate)
      }

      return user
    } catch (error) {
      await dispatch('wdots/add', { module: 'snauth', event: 'snSignInError', email, error: { code: error.code } }, { root: true })
      throw error
    }
  },

  async checkUserExistsSn ({ rootState }, { email }) {
    const project = rootState.projects.project

    const result = await httpsCallable(getFunctions(), 'snCheckUserExists')({ email, projectId: project.id })

    const snUserExists = dataToSnUserExists(result.data)

    return snUserExists
  },

  async registerSn ({ commit, dispatch, rootState }, userCandidate) {
    try {
      const project = rootState.projects.project

      const result = await httpsCallable(getFunctions(), 'snRegister')({ ...userCandidate, projectId: project.id })

      const token = result.data.token
      const newUserCandiate = result.data.user

      const userCredential = await signInWithCustomToken(getAuth(), token)
      const user = dataToUser(userCredential.user)
      commit('setUser', user)

      const projectUserExists = await dispatch('checkProjectUserExists', userCandidate.email)
      if (projectUserExists && !projectUserExists.exists && project.registrationEnabled) {
        await dispatch('registerProjectUser', newUserCandiate)
      }

      return user
    } catch (error) {
      await dispatch('wdots/add', { module: 'snauth', event: 'snRegisterError', email: userCandidate.email, error: { code: error.code } }, { root: true })
      throw error
    }
  },

  async resetPasswordSn ({ rootGetters }, { email }) {
    const project = rootGetters['projects/project']
    const site = rootGetters['sites/site']
    const defaultOrigin = rootGetters['sites/defaultOrigin']

    await httpsCallable(getFunctions(), 'snResetPassword')({ email, projectId: project.id, siteId: site.id, defaultOrigin })
  },

  showSnError ({ dispatch }, message) {
    const match = message.match(/^(?<tech>.*MC service.+:\s*)(?<user>.+)$/)

    let techMessage = null
    if (match) {
      techMessage = match.groups.tech
      message = match.groups.user
    } else {
      message = this.$t(message)
    }

    dispatch('notification/showNotification', { level: 'error', message, techMessage }, { root: true })
  }
}
