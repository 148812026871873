import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore'

const APP = 'vsites2'
const fullPropperties = () => {
  const fullProperties = {
    documentUrl: document.URL || null,
    documentReferrer: document.referrer || null,
    navigatorAgent: navigator.userAgent || null,
    navigatorPlatform: navigator.platform || null,
    navigatorLanguage: navigator.language || null,
    navigatorTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
    navigatorLocale: Intl.DateTimeFormat().resolvedOptions().locale || null,
    screenHeight: screen.height || null,
    screenWidth: screen.width || null
  }
  try {
    fullProperties.topUrl = window.top.location.href
  } catch (e) {}
  return fullProperties
}

const randomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export const state = () => ({
  isFirstLoad: true,

  localId: null,

  sessionId: null
})

export const mutations = {
  setFirstloadFalse (state) {
    state.isFirstLoad = false
  },

  initSessionId (state) {
    try {
      let sessionId = sessionStorage.getItem('sessionId')
      if (sessionId === null) {
        sessionStorage.setItem('sessionId', randomString(16))
        const storedItem = sessionStorage.getItem('sessionId')
        sessionId = storedItem || 'noSession'
      }
      state.sessionId = sessionId
    } catch (e) {}
  },

  initLocalId (state) {
    try {
      let localId = localStorage.getItem('localId')
      if (localId === null) {
        localStorage.setItem('localId', randomString(16))
        const storedItem = localStorage.getItem('localId')
        localId = storedItem || 'noLocal'
      }
      state.localId = localId
    } catch (e) {}
  }
}

export const actions = {
  async add ({ state, commit, rootState }, data) {
    try {
      if (state.isFirstLoad) {
        data.fullPropperties = fullPropperties()
        commit('initSessionId')
        commit('initLocalId')
        commit('setFirstloadFalse')
      }

      data.app = APP
      data.created = serverTimestamp()
      data.origin = window.location.origin
      data.uid = (rootState.auth.user && rootState.auth.user.uid) || null
      data.localId = state.localId
      data.localSessionId = state.sessionId
      data.projectId = rootState.projects.projectId || null

      const site = rootState.sites.site
      if (site) {
        data.siteId = site.id
        data.sessionIds = site.sections.sessionIds
      }

      await addDoc(collection(getFirestore(), 'wdots'), data)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error)
    }
  }
}
