<template>
  <v-text-field v-model="computedValue" :label="field.label" :required="field.required" :hint="field.hint" :solo="field.solo" :dense="field.dense" :rules="rules" :placeholder="examplePhoneNumber" persistent-hint @keydown.enter.native.prevent />
</template>

<script>
import { AsYouType, getExampleNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'

export default {
  name: 'PhoneField',

  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      formatter: new AsYouType(this.field.defaultCountry)
    }
  },

  computed: {
    examplePhoneNumber () {
      const phoneNumber = getExampleNumber(this.field.defaultCountry, examples)
      return phoneNumber ? phoneNumber.formatInternational() : null
    },

    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        if (value == null) {
          this.$emit('input', null)
        }
        this.formatter.reset()
        let result = this.formatter.input(value)
        if (this.formatter.getNumber()) {
          result = this.formatter.getNumber().formatInternational()
        }
        this.$emit('input', result)
      }
    },

    rules () {
      const rules = []

      if (this.field.required) {
        rules.push(v => !(!v || !v.trim()) || this.$t('v.fieldRequired'))
      }

      rules.push(v => !v || (this.formatter.getNumber() && this.formatter.getNumber().isValid()) || this.$t('v.phoneInvalid'))

      return rules
    }
  }
}
</script>
