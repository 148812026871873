import { removeEmailPrefix } from '@/helpers'

export default class User {
  constructor (data = {}) {
    this.uid = data.uid
    this.email = removeEmailPrefix(data.email)
    this.emailVerified = data.emailVerified
    Object.freeze(this)
  }
}
