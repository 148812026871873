const MxStates = {
  AGUASCALIENTES: 'Aguascalientes',
  BAJA_CALIFORNIA: 'Baja California',
  BAJA_CALIFORNIA_SUR: 'Baja California Sur',
  CAMPECHE: 'Campeche',
  CIUDAD_DE_MEXICO: 'Ciudad de México',
  CHIAPAS: 'Chiapas',
  CHIHUAHUA: 'Chihuahua',
  COAHUILA_DE_ZARAGOZA: 'Coahuila de Zaragoza',
  COLIMA: 'Colima',
  DURANGO: 'Durango',
  GUANAJUATO: 'Guanajuato',
  GUERRERO: 'Guerrero',
  HIDALGO: 'Hidalgo',
  JALISCO: 'Jalisco',
  MEXICO: 'México',
  MICHOACAN_DE_OCAMPO: 'Michoacán de Ocampo',
  MORELOS: 'Morelos',
  NAYARIT: 'Nayarit',
  NUEVO_LEON: 'Nuevo León',
  OAXACA: 'Oaxaca',
  PUEBLA: 'Puebla',
  QUERETARO: 'Querétaro',
  QUINTANA_ROO: 'Quintana Roo',
  SAN_LUIS_POTOSI: 'San Luis Potosí',
  SINALOA: 'Sinaloa',
  SONORA: 'Sonora',
  TABASCO: 'Tabasco',
  TAMAULIPAS: 'Tamaulipas',
  TLAXCALA: 'Tlaxcala',
  VERACRUZ: 'Veracruz',
  YUCATAN: 'Yucatán',
  ZACATECAS: 'Zacatecas',

  all: () => Object.values(MxStates)
    .filter(mxState => typeof mxState === 'string')
    .sort(),

  fromString: (string) => {
    const mxState = MxStates.all().find(mxState => mxState === string)
    if (!mxState) {
      throw new Error(`Unknown State: ${string}`)
    }
    return mxState
  }
}
Object.freeze(MxStates)

export default MxStates
