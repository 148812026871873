<template>
  <v-list-item :to="to" :exact="true" nuxt ripple class="pl-5" @click.stop="click">
    <v-list-item-content>
      <v-list-item-title :style="header.theme.style">
        <slot />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MenuItem',
  props: {
    to: {
      type: String,
      default: undefined
    },

    menu: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      header: 'sites/headerSection'
    })
  },

  methods: {
    click (event) {
      if (!this.menu) {
        this.setDrawer(false)
      }
      this.$emit('click', event)
    },

    ...mapActions([
      'setDrawer'
    ])
  }
}
</script>

<style scoped>
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before,
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0 !important;
}

.theme--dark.v-list-item {
  color: #ffffff;
}

.theme--light.v-list-item {
  color: rgba(0, 0, 0, 0.87);
}
</style>
