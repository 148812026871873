import Language from '@/models/Language'
import { capitalize, isEqual } from '@/helpers'

class Source {
  constructor (data) {
    this.language = data.language ? Language.fromString(data.language) : null
    this.label = data.label || (this.language ? this.language.localName : '')
    this.url = data.url

    Object.freeze(this)
  }
}

const SessionNotificationWrapper = Object.freeze({
  DIALOG: Object.freeze({ id: 'dialog', component: 'SessionNotificationDialog' }),
  SHEET: Object.freeze({ id: 'sheet', component: 'SessionNotificationSheet', tile: true }),

  all: () => Object.values(SessionNotificationWrapper).filter(wrapper => typeof wrapper === 'object'),

  fromString: (string) => {
    const wrapper = SessionNotificationWrapper.all().find(wrapper => wrapper.id === string)
    if (!wrapper) {
      // eslint-disable-next-line no-console
      console.log.error(`Unknown session notification wrapper: ${string}`)
      return null
    }
    return wrapper
  }
})

class SessionNotification {
  constructor (data) {
    if (!data) {
      data = {}
    }
    this.id = data.id
    this.publicId = data.publicId
    this.type = data.type
    this.name = data.name
    this.wrapper = SessionNotificationWrapper.fromString(data.wrapper) || SessionNotificationWrapper.DIALOG
    this.condition = data.condition
  }

  async init ({ dispatch }) {
  }

  get component () {
    return `${capitalize(this.type)}Notification`
  }
}

class MessageSessionNotification extends SessionNotification {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.message = data.message
  }
}

class NetworkingSessionNotification extends SessionNotification {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.title = data.title
    this.text = data.text
    this.imageUrl = data.imageUrl
    this.ctaLabel = data.ctaLabel
    this.meetingId = data.meetingId
    this.isWebinar = data.isWebinar
  }

  async init ({ dispatch }) {
    if (this.meetingId) {
      await dispatch('projects/fetchMeetingUrl', { meetingId: this.meetingId, isWebinar: this.isWebinar }, { root: true })
    }
  }
}

class QSessionNotification extends SessionNotification {
  constructor (data) {
    if (!data) {
      data = {}
    }
    super(data)

    this.title = data.title
    this.qId = data.qId
    this.questionIndex = data.questionIndex
  }

  async init ({ dispatch }) {
    await dispatch('qs/initQ', this.qId, { root: true })
  }
}

function dataToSessionNotification (data) {
  if (!data) {
    return null
  }

  if (!data.type) {
    data = { ...data, type: 'message' }
  }

  switch (data.type) {
    case 'message':
      return new MessageSessionNotification(data)
    case 'networking':
      return new NetworkingSessionNotification(data)
    case 'q':
      return new QSessionNotification(data)
    default:
      // eslint-disable-next-line no-console
      console.error(`Unknown session notification type: ${data.type}`)
      return null
  }
}

export default class Session {
  static Status = Object.freeze({
    HIDDEN: 'hidden',
    PRE: 'pre',
    LIVE: 'live',
    POST: 'post'
  })

  static SourceType = Object.freeze({
    BLANK: 'blank',
    IMAGE: 'image',
    URL: 'url',
    IFRAME: 'iframe'
  })

  static get id () {
    return 'default'
  }

  constructor (data = {}) {
    this.id = data.id

    this.name = data.name
    this.status = data.status

    this.date = data.date
    this.duration = data.duration

    this.commentsEnabled = data.commentsEnabled

    this.questionIndex = data.questionIndex

    this.message = data.message
    this.sourceType = data.sourceType
    this.sources = data.sources ? data.sources.map(source => new Source(source)) : []

    this.notification = dataToSessionNotification(data.notification)

    Object.freeze(this)
  }

  get hidden () {
    return this.status === Session.Status.HIDDEN
  }

  get pre () {
    return this.status === Session.Status.PRE
  }

  get live () {
    return this.status === Session.Status.LIVE
  }

  get post () {
    return this.status === Session.Status.POST
  }

  get blank () {
    return this.sourceType === Session.SourceType.BLANK
  }

  get image () {
    return this.sourceType === Session.SourceType.IMAGE
  }

  get url () {
    return this.sourceType === Session.SourceType.URL
  }

  get iframe () {
    return this.sourceType === Session.SourceType.IFRAME
  }

  defaultSource (locale, defaultLocale) {
    if (this.sources.length === 0) {
      return null
    }
    if (this.sources.length === 1) {
      return this.sources[0]
    }
    const localeSource = this.sources.find(source => source.language && source.language.code === locale)
    if (localeSource) {
      return localeSource
    }
    const defaultSource = this.sources.find(source => source.language && source.language.code === defaultLocale)
    if (defaultSource) {
      return defaultSource
    }
    return null
  }

  hasSource (source) {
    return !!this.sources.find(s => isEqual(s, source))
  }
}
