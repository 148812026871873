import * as moment from 'moment'

export default ({ $vuetify }, inject) => {
  inject('t', (message, ...params) => {
    if (!message.startsWith('v.')) {
      return $vuetify.lang.t(message, ...params)
    }

    return $vuetify.lang.t(`$vuetify.${message}`, ...params)
  })

  inject('setLocale', (locale) => {
    moment().locale(locale)
    $vuetify.lang.current = locale
  })
}
