const DEFAULT_NOTIFICATION_TIMEOUT = 5000
const DEFAULT_ERROR_TIMEOUT = 7000

export const state = () => ({
  notifications: []
})

export const getters = {
  notifications: state => state.notifications
}

export const mutations = {
  pushNotification (state, notification) {
    state.notifications.push(notification)
  },

  popNotification (state) {
    state.notifications.shift()
  }
}

export const actions = {
  hideNotification ({ commit }) {
    commit('popNotification')
  },

  showNotification ({ commit }, notification) {
    const defaultTimeout = ['error', 'warning'].includes(notification.level) ? DEFAULT_ERROR_TIMEOUT : DEFAULT_NOTIFICATION_TIMEOUT
    commit('pushNotification', { level: 'info', timeout: defaultTimeout, ...notification })
  },

  showMessage ({ dispatch }, message) {
    dispatch('showNotification', { message })
  },

  showWarning ({ dispatch }, message) {
    dispatch('showNotification', { message, level: 'warning' })
  },

  showError ({ dispatch }, message) {
    dispatch('showNotification', { message, level: 'error' })
  }
}
