import Score from './Score'

export default class Reply {
  constructor (data) {
    if (!data) {
      data = {}
    }

    this._id = data.id
    this._created = data.created
    this._modified = data.modified
    this._uid = data.uid
    this._email = data.email
    this._replies = data.replies ? { ...data.replies } : {}
    this._score = data.score ? new Score(data.score) : null
  }

  get id () {
    return this._id
  }

  get created () {
    return this._created
  }

  get modified () {
    return this._modified
  }

  get uid () {
    return this._uid
  }

  get email () {
    return this._email
  }

  get replies () {
    return this._replies
  }

  get score () {
    return this._score
  }
}
