<template>
  <v-autocomplete v-model="computedValue" :items="options" :label="field.label" :hint="field.hint" :solo="field.solo" :dense="field.dense" :rules="rules" persistent-hint clearable :menu-props="site.colors.notificationTheme" @keydown.enter.native.prevent />
</template>

<script>
import { mapGetters } from 'vuex'
import { localizeOptions } from '@/helpers'

export default {
  name: 'SelectField',

  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      default: null
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    options () {
      return localizeOptions(this.$t, this.field.options)
    },

    rules () {
      if (!this.field.required) {
        return []
      }
      return [v => !!v || this.$t('v.fieldRequired')]
    },

    ...mapGetters({
      site: 'sites/site'
    })
  }
}
</script>
