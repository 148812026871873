<template>
  <v-form ref="form" lazy-validation @submit.prevent="onReset">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          {{ $t('v.changePassword') }}
          <p class="mt-2">
            {{ $t('v.changePasswordDescription') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" class="py-2 grey--text">
          <v-icon aria-hidden="false">
            mdi-account
          </v-icon>
          {{ email }}
        </v-col>

        <field v-if="project.form.passwordField" v-model="password" :field="project.form.passwordField" :email="email" class="pt-0" />
      </v-row>

      <v-row align="center" class="pt-11">
        <v-spacer />
        <v-col cols="auto">
          <v-btn :small="project.form.small" :loading="loading" :disabled="!resetEnabled" color="accent" class="px-11" type="submit">
            {{ $t('v.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepMixin from './StepMixin'
import Field from '@/components/auth/Field'

export default {
  components: {
    Field
  },

  mixins: [
    StepMixin
  ],

  data: () => ({
    email: null,
    code: null,
    password: '',
    loading: false
  }),

  computed: {
    resetEnabled () {
      return this.password && !this.loading
    },

    ...mapGetters({
      project: 'projects/project'
    })
  },

  async mounted () {
    const reset = await this.usePasswordReset()
    if (reset) {
      this.code = reset.code
      this.email = reset.email
    }
  },

  methods: {
    async onReset () {
      if (!this.$refs.form.validate()) {
        return false
      }

      try {
        this.loading = true

        await this.wdotAdd({ event: 'tryChangePassword', email: this.email })

        await this.confirmPasswordReset({ code: this.code, password: this.password })

        this.showMessage(this.$t('v.changePasswordSuccess'))

        this.next({ email: this.email })
      } catch (error) {
        this.showError(this.$t(error.message))
        this.$logError(error)
      } finally {
        this.loading = false
      }
    },

    ...mapActions({
      usePasswordReset: 'auth/usePasswordReset',
      verifyPasswordResetCode: 'auth/verifyPasswordResetCode',
      confirmPasswordReset: 'auth/confirmPasswordReset',
      wdotAdd: 'wdots/add',
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
</style>
