<template>
  <div>
    <span :class="{ row: field.row }">
      {{ field.label }}
    </span>
    <v-radio-group v-model="computedValue" :mandatory="false" :hint="field.hint" :row="field.row" :dense="field.dense" :rules="rules" class="mt-2" persistent-hint>
      <v-radio v-for="(option, index) in options" :key="index" :label="option.text" :value="option.value" />
    </v-radio-group>
  </div>
</template>

<script>
import { localizeOptions } from '@/helpers'

export default {
  name: 'RadioField',

  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      default: null
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    options () {
      return localizeOptions(this.$t, this.field.options)
    },

    rules () {
      const rules = []

      if (this.field.required) {
        rules.push(v => !(!v || !v.trim()) || this.$t('v.fieldRequired'))
      }

      if (this.field.rules) {
        rules.push(...this.field.rules)
      }

      return rules
    }
  }
}
</script>

<style scoped>
.row {
  float: left;
  margin-top: 6px;
  margin-right: 12px;
  margin-left: 0px;
}
</style>
