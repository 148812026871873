<template>
  <v-app>
    <navigation />
    <v-main>
      <nuxt />
    </v-main>
    <disclaimer />
    <notification />
    <Dialog />
    <LoginDialog />
    <LoginNativeDialog />
  </v-app>
</template>

<script>
import Navigation from '@/components/navigation/Navigation'
import Notification from '@/components/Notification'
import Dialog from '@/components/Dialog'
import Disclaimer from '@/components/Disclaimer'
import LoginDialog from '@/components/LoginDialog'
import LoginNativeDialog from '@/components/auth/LoginNativeDialog'

export default {
  components: {
    Navigation,
    Notification,
    Dialog,
    Disclaimer,
    LoginDialog,
    LoginNativeDialog
  }
}
</script>

<style>
.container:not(.container--fluid) {
  max-width: 960px !important;
}
</style>
