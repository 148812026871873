import { getFunctions, httpsCallable } from 'firebase/functions'
import Site from '@/models/Site'
import { Sections } from '@/models/sections'
import { AuthError, NotFoundError } from '@/models/errors'
import { storeItem, retrieveItem } from '@/helpers'

export const state = () => ({
  path: null,

  site: null,

  siteInitialized: false,

  sitePassword: null,

  disclaimerAccepted: false
})

export const getters = {
  path: state => state.path || '',

  pathPrefix: (state, getters) => getters.path.length > 0 ? getters.path.substring(1) + '_' : '',

  site: state => state.site,

  siteInitialized: state => state.siteInitialized,

  defaultOrigin: () => process.env.VSITES_URL,

  filterSections: (state, getters, rootState, rootGetters) => (sections) => {
    const evaluateCondition = rootGetters['userdots/evaluateCondition']
    return sections.filter(section => section.visible(evaluateCondition))
  },

  sections: (state, getters) => {
    const site = getters.site
    if (!site) {
      return new Sections()
    }

    return site.sections
  },

  main: (state, getters) => getters.filterSections(getters.sections.main),

  header: (state, getters) => getters.filterSections(getters.sections.header),

  footer: (state, getters) => getters.filterSections(getters.sections.footer),

  links: (state, getters) => {
    const links = getters.main.filter(section => section.showOnMenu && section.anchor && section.title)
      .map(section => ({ title: section.title, anchor: section.anchor }))

    return links
  },

  headerSection: (state, getters) => getters.header.find(section => section.component === 'HeaderSection'),

  disclaimer: state => (state.site && state.site.disclaimer.enabled && !state.disclaimerAccepted) ? state.site.disclaimer : null
}

export const mutations = {
  setPath (state, path) {
    state.path = path
  },

  setSite (state, site) {
    state.site = site
  },

  siteInitialized (state) {
    state.siteInitialized = true
  },

  setSitePassword (state, sitePassword) {
    state.sitePassword = sitePassword
  },

  acceptDisclaimer (state) {
    state.disclaimerAccepted = true
  }
}

function dataToSite (data) {
  return new Site(data)
}

export const actions = {
  async initSite ({ commit, dispatch }, { origin, path }) {
    commit('setPath', path)
    await dispatch('fetchSitePassword')
    await dispatch('initDisclaimerAccepted')
    await dispatch('fetchSite', { origin, path })
    commit('siteInitialized')
  },

  async fetchSite ({ commit, state, getters, dispatch }, { id, origin, path }) {
    if (!id && state.site) {
      return state.site
    }

    const password = state.sitePassword
    const defaultOrigin = getters.defaultOrigin

    try {
      const result = await httpsCallable(getFunctions(), 'getSite')({ id, origin, defaultOrigin, path, password })

      const siteData = result.data
      if (!siteData) {
        throw new NotFoundError('Missing site data')
      }
      const site = dataToSite(siteData)

      if (site.redirectUrl) {
        dispatch('redirect', site.redirectUrl)
        return
      }

      commit('setSite', site)
      dispatch('setLocale', site.language ? site.language.code : null, { root: true })

      dispatch('analytics/visitPage', path, { root: true })

      if (site.auth) {
        try {
          await dispatch('auth/initProjectUser', null, { root: true })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error initializing project user: ${error.message}`)
          throw error
        }
      }

      if (site.projectId) {
        try {
          const sessionIds = site.sections.sessionIds
          await dispatch('projects/initProject', { projectId: site.projectId, sessionIds }, { root: true })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error initializing project: ${error.message}`)
          throw error
        }
      }

      dispatch('fetchUpdatedSite', site.id)
    } catch (error) {
      if (error.code === 'functions/not-found') {
        throw new NotFoundError(error.message)
      } else if (error.code === 'functions/unauthenticated') {
        throw new AuthError(401, 'Missing site password')
      } else if (error.code === 'functions/permission-denied') {
        throw new AuthError(403, 'Invalid site password')
      }
      throw error
    }
  },

  async fetchUpdatedSite ({ commit, state }, id) {
    const password = state.sitePassword
    const result = await httpsCallable(getFunctions(), 'getUpdatedSite')({ id, password })

    const siteData = result.data
    if (siteData) {
      const site = dataToSite(siteData)
      commit('setSite', site)
    }
  },

  async refreshSite ({ dispatch, state }) {
    if (!state.site) {
      return
    }
    await dispatch('fetchSite', { id: state.site.id })
  },

  setTheme ({ _ }, { site, app, vuetify }) {
    if (site.colors.background) {
      app.style.backgroundColor = site.colors.background
      document.documentElement.style.setProperty('--backgroundColor', site.colors.background)
    } else {
      document.documentElement.style.setProperty('--backgroundColor', getComputedStyle(app).backgroundColor)
    }
    if (site.colors.text) {
      app.style.color = site.colors.text
      document.documentElement.style.setProperty('--textColor', site.colors.text)
    } else {
      document.documentElement.style.setProperty('--textColor', getComputedStyle(app).color)
    }

    vuetify.theme.isDark = site.colors.dark

    const theme = vuetify.theme.themes[site.colors.dark ? 'dark' : 'light']
    if (site.colors.primary) {
      theme.primary = site.colors.primary
    }
    if (site.colors.secondary) {
      theme.secondary = site.colors.secondary
    }
    if (site.colors.accent) {
      theme.accent = site.colors.accent
    }

    if (site.fontFamily) {
      document.documentElement.style.setProperty('--fontFamily', site.fontFamilyName)
    }
    if (site.headingFontFamily) {
      document.documentElement.style.setProperty('--headingFontFamily', site.headingFontFamilyName)
    }
  },

  fetchSitePassword ({ commit, state, getters }) {
    if (state.sitePassword) {
      return
    }

    const password = retrieveItem(`${getters.pathPrefix}password`)
    commit('setSitePassword', password)
  },

  enterSitePassword ({ commit, getters }, password) {
    commit('setSitePassword', password)
    storeItem(`${getters.pathPrefix}password`, password)
  },

  selectVariant ({ getters }, variant) {
    const site = getters.site
    if (site.language.code !== variant.language.code) {
      location.href = variant.url
    }
  },

  redirect ({ _ }, url) {
    let target = url
    const search = window.location.search
    if (search) {
      target += target.includes('?') ? search.replace('?', '&') : search
    }
    target += window.location.hash
    window.location.replace(target)
  },

  initDisclaimerAccepted ({ commit, getters }) {
    const disclaimerAccepted = !!retrieveItem(`${getters.pathPrefix}disclaimer`)
    if (disclaimerAccepted) {
      commit('acceptDisclaimer')
    }
  },

  acceptDisclaimer ({ commit, getters }) {
    commit('acceptDisclaimer')
    storeItem(`${getters.pathPrefix}disclaimer`, true)
  }
}
