import { AuthError, NotFoundError } from '@/models/errors'
import { toQueryString } from '@/helpers'

export default async function ({ store, params, query, route, redirect, error }) {
  if (route.meta.some(meta => meta.public)) {
    return
  }

  try {
    await store.dispatch('auth/init')
    await store.dispatch('sites/initSite', { origin: window.location.origin, path: route.path })
  } catch (e) {
    if (e instanceof NotFoundError) {
      error({ statusCode: 404, message: e.message })
      return
    } else if (e instanceof AuthError) {
      redirect('/pass', { ...params, query: toQueryString(query), statusCode: e.status })
      return
    }
    throw e
  } finally {
    store.dispatch('wdots/add', { event: 'pageview', params, query })
  }
}
