<template>
  <v-navigation-drawer v-if="header" v-model="drawer" :style="header.theme.style" fixed temporary right disable-resize-watcher disable-route-watcher width="180" v-bind="header.theme.theme">
    <navigation-item v-for="link in links" :key="link.anchor" :to="`#${link.anchor}`">
      {{ link.title }}
    </navigation-item>
    <language-switcher v-if="site.variants" :language="site.language" :variants="site.variants" v-bind="header.theme.theme" />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import NavigationItem from './NavigationItem'
import LanguageSwitcher from '@/components/navigation/LanguageSwitcher'

export default {
  components: {
    NavigationItem,
    LanguageSwitcher
  },

  computed: {
    drawer: {
      get () {
        return this.drawerValue
      },
      set (value) {
        this.setDrawer(value)
      }
    },

    ...mapGetters({
      drawerValue: 'drawer',
      site: 'sites/site',
      links: 'sites/links',
      header: 'sites/headerSection'
    })
  },

  methods: {
    ...mapActions({
      setDrawer: 'setDrawer',
      showRegistrationDialog: 'dialog/showRegistrationDialog'
    })
  }
}
</script>

<style scoped>
.v-navigation-drawer {
  top: var(--headerHeight, 0) !important;
}
</style>
