<template>
  <v-dialog v-if="project && !disclaimer" :value="loginNativeDialog" overlay-opacity="0.8" max-width="600px" content-class="mx-0" v-bind="site.colors.notificationTheme" @input="hideLoginNativeDialog">
    <v-card id="login">
      <v-card-title class="py-1">
        <v-row>
          <v-spacer />
          <v-col cols="2" class="pt-4 pb-2 text-right">
            <v-btn icon @click="hideLoginNativeDialog">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <workflow v-if="loginNativeDialog" :workflow="workflow" @success="success" @close="hideLoginNativeDialog" />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Workflow from './Workflow'

export default {
  components: {
    Workflow
  },

  computed: {
    ...mapGetters({
      loginNativeDialog: 'auth/loginNativeDialog',
      workflow: 'auth/workflow',
      project: 'projects/project',
      site: 'sites/site',
      disclaimer: 'sites/disclaimer'
    })
  },

  methods: {
    async success () {
      try {
        await this.initProjectUser()
        await this.refreshSite()
      } catch (error) {
        this.$handleError(error)
      }
    },

    ...mapActions({
      hideLoginNativeDialog: 'auth/hideLoginNativeDialog',
      initProjectUser: 'auth/initProjectUser',
      refreshSite: 'sites/refreshSite',
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
</style>
