<template>
  <v-form ref="form" lazy-validation @submit.prevent="onSignin">
    <v-card-text>
      <v-row>
        <v-col v-if="project.form.signInDescription" cols="12" v-html="project.form.signInDescription" />
        <v-col v-else cols="12">
          {{ $t('v.signIn') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="emailModel" :solo="project.form.solo" :outlined="project.form.outlined" :rules="emailRules" label="Email" name="email" type="email" required autofocus autocapitalize="none" autocomplete="username" :dense="project.form.small" @keydown.native.space.prevent />
        </v-col>
        <v-col cols="12" class="pb-11">
          <v-text-field v-model="password" name="password" :label="$t('v.password')" :type="showPassword ? 'text' : 'password'" :solo="project.form.solo" :dense="project.form.small" :outlined="project.form.outlined" required autocomplete="current-password">
            <template #append>
              <v-btn icon tabindex="-1" @click="showPassword = !showPassword">
                <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-spacer />
        <v-col cols="auto">
          <v-btn :small="project.form.small" :loading="loading" :disabled="loading" color="accent" class="px-11" type="submit">
            {{ $t('v.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StepMixin from './StepMixin'
import { isEmail } from '@/helpers'

export default {
  mixins: [
    StepMixin
  ],

  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    loading: false
  }),

  computed: {
    emailModel: {
      get () {
        return this.email
      },
      set (value) {
        this.email = value ? value.replace(/\s/g, '') : ''
      }
    },

    emailRules () {
      return [v => !!v || this.$t('v.emailRequired'), v => isEmail(v) || this.$t('v.emailInvalid')]
    },

    ...mapGetters({
      project: 'projects/project'
    })
  },

  async mounted () {
    const prefillEmail = await this.usePrefillEmail()
    if (prefillEmail) {
      this.email = prefillEmail
    }
  },

  methods: {
    async onSignin () {
      if (!this.$refs.form.validate()) {
        return false
      }

      try {
        this.loading = true

        const email = this.email.toLowerCase().trim()
        await this.wdotAdd({ event: 'trySignIn', email })

        await this.signInSn({ email, password: this.password })

        this.next()
      } catch (error) {
        this.showSnError(error.message)
        this.$logError(error)
      } finally {
        this.loading = false
      }
    },

    ...mapActions({
      signInSn: 'auth/signInSn',
      usePrefillEmail: 'auth/usePrefillEmail',
      showSnError: 'auth/showSnError',
      wdotAdd: 'wdots/add'
    })
  }
}
</script>

<style scoped>
</style>
