class Country {
  constructor (data) {
    if (!data.code) {
      throw new Error('Missing country code')
    }
    if (!data.name) {
      throw new Error('Missing country name')
    }

    this.code = data.code
    this.name = data.name
    Object.freeze(this)
  }
}

const Countries = {
  AFGHANISTAN: new Country({ code: 'AF', name: 'Afghanistan' }),
  ALAND_ISLANDS: new Country({ code: 'AX', name: 'Åland Islands' }),
  ALBANIA: new Country({ code: 'AL', name: 'Albania' }),
  ALGERIA: new Country({ code: 'DZ', name: 'Algeria' }),
  AMERICAN_SAMOA: new Country({ code: 'AS', name: 'American Samoa' }),
  ANDORRA: new Country({ code: 'AD', name: 'Andorra' }),
  ANGOLA: new Country({ code: 'AO', name: 'Angola' }),
  ANGUILLA: new Country({ code: 'AI', name: 'Anguilla' }),
  ANTARCTICA: new Country({ code: 'AQ', name: 'Antarctica' }),
  ANTIGUA_AND_BARBUDA: new Country({ code: 'AG', name: 'Antigua and Barbuda' }),
  ARGENTINA: new Country({ code: 'AR', name: 'Argentina' }),
  ARMENIA: new Country({ code: 'AM', name: 'Armenia' }),
  ARUBA: new Country({ code: 'AW', name: 'Aruba' }),
  AUSTRALIA: new Country({ code: 'AU', name: 'Australia' }),
  AUSTRIA: new Country({ code: 'AT', name: 'Austria' }),
  AZERBAIJAN: new Country({ code: 'AZ', name: 'Azerbaijan' }),
  BAHAMAS: new Country({ code: 'BS', name: 'Bahamas' }),
  BAHRAIN: new Country({ code: 'BH', name: 'Bahrain' }),
  BANGLADESH: new Country({ code: 'BD', name: 'Bangladesh' }),
  BARBADOS: new Country({ code: 'BB', name: 'Barbados' }),
  BELARUS: new Country({ code: 'BY', name: 'Belarus' }),
  BELGIUM: new Country({ code: 'BE', name: 'Belgium' }),
  BELIZE: new Country({ code: 'BZ', name: 'Belize' }),
  BENIN: new Country({ code: 'BJ', name: 'Benin' }),
  BERMUDA: new Country({ code: 'BM', name: 'Bermuda' }),
  BHUTAN: new Country({ code: 'BT', name: 'Bhutan' }),
  BOLIVIA: new Country({ code: 'BO', name: 'Bolivia' }),
  BONAIRE_SINT_EUSTATIUS_AND_SABA: new Country({ code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' }),
  BOSNIA_AND_HERZEGOVINA: new Country({ code: 'BA', name: 'Bosnia and Herzegovina' }),
  BOTSWANA: new Country({ code: 'BW', name: 'Botswana' }),
  BOUVET_ISLAND: new Country({ code: 'BV', name: 'Bouvet Island' }),
  BRAZIL: new Country({ code: 'BR', name: 'Brazil' }),
  BRITISH_INDIAN_OCEAN_TERRITORY: new Country({ code: 'IO', name: 'British Indian Ocean Territory' }),
  BRUNEI_DARUSSALAM: new Country({ code: 'BN', name: 'Brunei Darussalam' }),
  BULGARIA: new Country({ code: 'BG', name: 'Bulgaria' }),
  BURKINA_FASO: new Country({ code: 'BF', name: 'Burkina Faso' }),
  BURUNDI: new Country({ code: 'BI', name: 'Burundi' }),
  CAMBODIA: new Country({ code: 'KH', name: 'Cambodia' }),
  CAMEROON: new Country({ code: 'CM', name: 'Cameroon' }),
  CANADA: new Country({ code: 'CA', name: 'Canada' }),
  CAPE_VERDE: new Country({ code: 'CV', name: 'Cape Verde' }),
  CAYMAN_ISLANDS: new Country({ code: 'KY', name: 'Cayman Islands' }),
  CENTRAL_AFRICAN_REPUBLIC: new Country({ code: 'CF', name: 'Central African Republic' }),
  CHAD: new Country({ code: 'TD', name: 'Chad' }),
  CHILE: new Country({ code: 'CL', name: 'Chile' }),
  CHINA: new Country({ code: 'CN', name: 'China' }),
  CHRISTMAS_ISLAND: new Country({ code: 'CX', name: 'Christmas Island' }),
  COCOS_ISLANDS: new Country({ code: 'CC', name: 'Cocos Islands' }),
  COLOMBIA: new Country({ code: 'CO', name: 'Colombia' }),
  COMOROS: new Country({ code: 'KM', name: 'Comoros' }),
  CONGO_BRAZZAVILLE: new Country({ code: 'CG', name: 'Congo-Brazzaville' }),
  CONGO_KINSHASA: new Country({ code: 'CD', name: 'Congo-Kinshasa' }),
  COOK_ISLANDS: new Country({ code: 'CK', name: 'Cook Islands' }),
  COSTA_RICA: new Country({ code: 'CR', name: 'Costa Rica' }),
  IVORY_COAST: new Country({ code: 'CI', name: 'Ivory Coast' }),
  CROATIA: new Country({ code: 'HR', name: 'Croatia' }),
  CUBA: new Country({ code: 'CU', name: 'Cuba' }),
  CURACAO: new Country({ code: 'CW', name: 'Curaçao' }),
  CYPRUS: new Country({ code: 'CY', name: 'Cyprus' }),
  CZECH_REPUBLIC: new Country({ code: 'CZ', name: 'Czech Republic' }),
  DENMARK: new Country({ code: 'DK', name: 'Denmark' }),
  DJIBOUTI: new Country({ code: 'DJ', name: 'Djibouti' }),
  DOMINICA: new Country({ code: 'DM', name: 'Dominica' }),
  DOMINICAN_REPUBLIC: new Country({ code: 'DO', name: 'Dominican Republic' }),
  ECUADOR: new Country({ code: 'EC', name: 'Ecuador' }),
  EGYPT: new Country({ code: 'EG', name: 'Egypt' }),
  EL_SALVADOR: new Country({ code: 'SV', name: 'El Salvador' }),
  EQUATORIAL_GUINEA: new Country({ code: 'GQ', name: 'Equatorial Guinea' }),
  ERITREA: new Country({ code: 'ER', name: 'Eritrea' }),
  ESTONIA: new Country({ code: 'EE', name: 'Estonia' }),
  ETHIOPIA: new Country({ code: 'ET', name: 'Ethiopia' }),
  FALKLAND_ISLANDS: new Country({ code: 'FK', name: 'Falkland Islands' }),
  FAROE_ISLANDS: new Country({ code: 'FO', name: 'Faroe Islands' }),
  FIJI: new Country({ code: 'FJ', name: 'Fiji' }),
  FINLAND: new Country({ code: 'FI', name: 'Finland' }),
  FRANCE: new Country({ code: 'FR', name: 'France' }),
  FRENCH_GUIANA: new Country({ code: 'GF', name: 'French Guiana' }),
  FRENCH_POLYNESIA: new Country({ code: 'PF', name: 'French Polynesia' }),
  FRENCH_SOUTHERN_TERRITORIES: new Country({ code: 'TF', name: 'French Southern Territories' }),
  GABON: new Country({ code: 'GA', name: 'Gabon' }),
  GAMBIA: new Country({ code: 'GM', name: 'Gambia' }),
  GEORGIA: new Country({ code: 'GE', name: 'Georgia' }),
  GERMANY: new Country({ code: 'DE', name: 'Germany' }),
  GHANA: new Country({ code: 'GH', name: 'Ghana' }),
  GIBRALTAR: new Country({ code: 'GI', name: 'Gibraltar' }),
  GREECE: new Country({ code: 'GR', name: 'Greece' }),
  GREENLAND: new Country({ code: 'GL', name: 'Greenland' }),
  GRENADA: new Country({ code: 'GD', name: 'Grenada' }),
  GUADELOUPE: new Country({ code: 'GP', name: 'Guadeloupe' }),
  GUAM: new Country({ code: 'GU', name: 'Guam' }),
  GUATEMALA: new Country({ code: 'GT', name: 'Guatemala' }),
  GUERNSEY: new Country({ code: 'GG', name: 'Guernsey' }),
  GUINEA: new Country({ code: 'GN', name: 'Guinea' }),
  GUINEA_BISSAU: new Country({ code: 'GW', name: 'Guinea-Bissau' }),
  GUYANA: new Country({ code: 'GY', name: 'Guyana' }),
  HAITI: new Country({ code: 'HT', name: 'Haiti' }),
  HEARD_ISLAND_AND_MCDONALD_ISLANDS: new Country({ code: 'HM', name: 'Heard Island and McDonald Islands' }),
  VATICAN: new Country({ code: 'VA', name: 'Vatican' }),
  HONDURAS: new Country({ code: 'HN', name: 'Honduras' }),
  HONG_KONG: new Country({ code: 'HK', name: 'Hong Kong' }),
  HUNGARY: new Country({ code: 'HU', name: 'Hungary' }),
  ICELAND: new Country({ code: 'IS', name: 'Iceland' }),
  INDIA: new Country({ code: 'IN', name: 'India' }),
  INDONESIA: new Country({ code: 'ID', name: 'Indonesia' }),
  IRAN: new Country({ code: 'IR', name: 'Iran' }),
  IRAQ: new Country({ code: 'IQ', name: 'Iraq' }),
  IRELAND: new Country({ code: 'IE', name: 'Ireland' }),
  ISLE_OF_MAN: new Country({ code: 'IM', name: 'Isle of Man' }),
  ISRAEL: new Country({ code: 'IL', name: 'Israel' }),
  ITALY: new Country({ code: 'IT', name: 'Italy' }),
  JAMAICA: new Country({ code: 'JM', name: 'Jamaica' }),
  JAPAN: new Country({ code: 'JP', name: 'Japan' }),
  JERSEY: new Country({ code: 'JE', name: 'Jersey' }),
  JORDAN: new Country({ code: 'JO', name: 'Jordan' }),
  KAZAKHSTAN: new Country({ code: 'KZ', name: 'Kazakhstan' }),
  KENYA: new Country({ code: 'KE', name: 'Kenya' }),
  KIRIBATI: new Country({ code: 'KI', name: 'Kiribati' }),
  NORTH_KOREA: new Country({ code: 'KP', name: 'North Korea' }),
  SOUTH_KOREA: new Country({ code: 'KR', name: 'South Korea' }),
  KUWAIT: new Country({ code: 'KW', name: 'Kuwait' }),
  KYRGYZSTAN: new Country({ code: 'KG', name: 'Kyrgyzstan' }),
  LAOS: new Country({ code: 'LA', name: 'Laos' }),
  LATVIA: new Country({ code: 'LV', name: 'Latvia' }),
  LEBANON: new Country({ code: 'LB', name: 'Lebanon' }),
  LESOTHO: new Country({ code: 'LS', name: 'Lesotho' }),
  LIBERIA: new Country({ code: 'LR', name: 'Liberia' }),
  LIBYA: new Country({ code: 'LY', name: 'Libya' }),
  LIECHTENSTEIN: new Country({ code: 'LI', name: 'Liechtenstein' }),
  LITHUANIA: new Country({ code: 'LT', name: 'Lithuania' }),
  LUXEMBOURG: new Country({ code: 'LU', name: 'Luxembourg' }),
  MACAO: new Country({ code: 'MO', name: 'Macao' }),
  MACEDONIA: new Country({ code: 'MK', name: 'Macedonia' }),
  MADAGASCAR: new Country({ code: 'MG', name: 'Madagascar' }),
  MALAWI: new Country({ code: 'MW', name: 'Malawi' }),
  MALAYSIA: new Country({ code: 'MY', name: 'Malaysia' }),
  MALDIVES: new Country({ code: 'MV', name: 'Maldives' }),
  MALI: new Country({ code: 'ML', name: 'Mali' }),
  MALTA: new Country({ code: 'MT', name: 'Malta' }),
  MARSHALL_ISLANDS: new Country({ code: 'MH', name: 'Marshall Islands' }),
  MARTINIQUE: new Country({ code: 'MQ', name: 'Martinique' }),
  MAURITANIA: new Country({ code: 'MR', name: 'Mauritania' }),
  MAURITIUS: new Country({ code: 'MU', name: 'Mauritius' }),
  MAYOTTE: new Country({ code: 'YT', name: 'Mayotte' }),
  MEXICO: new Country({ code: 'MX', name: 'Mexico' }),
  MICRONESIA: new Country({ code: 'FM', name: 'Micronesia' }),
  MOLDOVA: new Country({ code: 'MD', name: 'Moldova' }),
  MONACO: new Country({ code: 'MC', name: 'Monaco' }),
  MONGOLIA: new Country({ code: 'MN', name: 'Mongolia' }),
  MONTENEGRO: new Country({ code: 'ME', name: 'Montenegro' }),
  MONTSERRAT: new Country({ code: 'MS', name: 'Montserrat' }),
  MOROCCO: new Country({ code: 'MA', name: 'Morocco' }),
  MOZAMBIQUE: new Country({ code: 'MZ', name: 'Mozambique' }),
  MYANMAR: new Country({ code: 'MM', name: 'Myanmar' }),
  NAMIBIA: new Country({ code: 'NA', name: 'Namibia' }),
  NAURU: new Country({ code: 'NR', name: 'Nauru' }),
  NEPAL: new Country({ code: 'NP', name: 'Nepal' }),
  NETHERLANDS: new Country({ code: 'NL', name: 'Netherlands' }),
  NEW_CALEDONIA: new Country({ code: 'NC', name: 'New Caledonia' }),
  NEW_ZEALAND: new Country({ code: 'NZ', name: 'New Zealand' }),
  NICARAGUA: new Country({ code: 'NI', name: 'Nicaragua' }),
  NIGER: new Country({ code: 'NE', name: 'Niger' }),
  NIGERIA: new Country({ code: 'NG', name: 'Nigeria' }),
  NIUE: new Country({ code: 'NU', name: 'Niue' }),
  NORFOLK_ISLAND: new Country({ code: 'NF', name: 'Norfolk Island' }),
  NORTHERN_MARIANA_ISLANDS: new Country({ code: 'MP', name: 'Northern Mariana Islands' }),
  NORWAY: new Country({ code: 'NO', name: 'Norway' }),
  OMAN: new Country({ code: 'OM', name: 'Oman' }),
  PAKISTAN: new Country({ code: 'PK', name: 'Pakistan' }),
  PALAU: new Country({ code: 'PW', name: 'Palau' }),
  PALESTINE: new Country({ code: 'PS', name: 'Palestine' }),
  PANAMA: new Country({ code: 'PA', name: 'Panama' }),
  PAPUA_NEW_GUINEA: new Country({ code: 'PG', name: 'Papua New Guinea' }),
  PARAGUAY: new Country({ code: 'PY', name: 'Paraguay' }),
  PERU: new Country({ code: 'PE', name: 'Peru' }),
  PHILIPPINES: new Country({ code: 'PH', name: 'Philippines' }),
  PITCAIRN: new Country({ code: 'PN', name: 'Pitcairn' }),
  POLAND: new Country({ code: 'PL', name: 'Poland' }),
  PORTUGAL: new Country({ code: 'PT', name: 'Portugal' }),
  PUERTO_RICO: new Country({ code: 'PR', name: 'Puerto Rico' }),
  QATAR: new Country({ code: 'QA', name: 'Qatar' }),
  REUNION: new Country({ code: 'RE', name: 'Réunion' }),
  ROMANIA: new Country({ code: 'RO', name: 'Romania' }),
  RUSSIA: new Country({ code: 'RU', name: 'Russia' }),
  RWANDA: new Country({ code: 'RW', name: 'Rwanda' }),
  SAINT_BARTHELEMY: new Country({ code: 'BL', name: 'Saint Barthélemy' }),
  SAINT_HELENA: new Country({ code: 'SH', name: 'Saint Helena' }),
  SAINT_KITTS_AND_NEVIS: new Country({ code: 'KN', name: 'Saint Kitts and Nevis' }),
  SAINT_LUCIA: new Country({ code: 'LC', name: 'Saint Lucia' }),
  SAINT_MARTIN: new Country({ code: 'MF', name: 'Saint Martin' }),
  SAINT_PIERRE_AND_MIQUELON: new Country({ code: 'PM', name: 'Saint Pierre and Miquelon' }),
  SAINT_VINCENT_AND_THE_GRENADINES: new Country({ code: 'VC', name: 'Saint Vincent and the Grenadines' }),
  SAMOA: new Country({ code: 'WS', name: 'Samoa' }),
  SAN_MARINO: new Country({ code: 'SM', name: 'San Marino' }),
  SAO_TOME_AND_PRINCIPE: new Country({ code: 'ST', name: 'Sao Tome and Principe' }),
  SAUDI_ARABIA: new Country({ code: 'SA', name: 'Saudi Arabia' }),
  SENEGAL: new Country({ code: 'SN', name: 'Senegal' }),
  SERBIA: new Country({ code: 'RS', name: 'Serbia' }),
  SEYCHELLES: new Country({ code: 'SC', name: 'Seychelles' }),
  SIERRA_LEONE: new Country({ code: 'SL', name: 'Sierra Leone' }),
  SINGAPORE: new Country({ code: 'SG', name: 'Singapore' }),
  SINT_MAARTEN: new Country({ code: 'SX', name: 'Sint Maarten' }),
  SLOVAKIA: new Country({ code: 'SK', name: 'Slovakia' }),
  SLOVENIA: new Country({ code: 'SI', name: 'Slovenia' }),
  SOLOMON_ISLANDS: new Country({ code: 'SB', name: 'Solomon Islands' }),
  SOMALIA: new Country({ code: 'SO', name: 'Somalia' }),
  SOUTH_AFRICA: new Country({ code: 'ZA', name: 'South Africa' }),
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS: new Country({ code: 'GS', name: 'South Georgia and the South Sandwich Islands' }),
  SOUTH_SUDAN: new Country({ code: 'SS', name: 'South Sudan' }),
  SPAIN: new Country({ code: 'ES', name: 'Spain' }),
  SRI_LANKA: new Country({ code: 'LK', name: 'Sri Lanka' }),
  SUDAN: new Country({ code: 'SD', name: 'Sudan' }),
  SURINAME: new Country({ code: 'SR', name: 'Suriname' }),
  SVALBARD_AND_JAN_MAYEN: new Country({ code: 'SJ', name: 'Svalbard and Jan Mayen' }),
  SWAZILAND: new Country({ code: 'SZ', name: 'Swaziland' }),
  SWEDEN: new Country({ code: 'SE', name: 'Sweden' }),
  SWITZERLAND: new Country({ code: 'CH', name: 'Switzerland' }),
  SYRIA: new Country({ code: 'SY', name: 'Syria' }),
  TAIWAN: new Country({ code: 'TW', name: 'Taiwan' }),
  TAJIKISTAN: new Country({ code: 'TJ', name: 'Tajikistan' }),
  TANZANIA: new Country({ code: 'TZ', name: 'Tanzania' }),
  THAILAND: new Country({ code: 'TH', name: 'Thailand' }),
  TIMOR_LESTE: new Country({ code: 'TL', name: 'Timor-Leste' }),
  TOGO: new Country({ code: 'TG', name: 'Togo' }),
  TOKELAU: new Country({ code: 'TK', name: 'Tokelau' }),
  TONGA: new Country({ code: 'TO', name: 'Tonga' }),
  TRINIDAD_AND_TOBAGO: new Country({ code: 'TT', name: 'Trinidad and Tobago' }),
  TUNISIA: new Country({ code: 'TN', name: 'Tunisia' }),
  TURKEY: new Country({ code: 'TR', name: 'Turkey' }),
  TURKMENISTAN: new Country({ code: 'TM', name: 'Turkmenistan' }),
  TURKS_AND_CAICOS_ISLANDS: new Country({ code: 'TC', name: 'Turks and Caicos Islands' }),
  TUVALU: new Country({ code: 'TV', name: 'Tuvalu' }),
  UGANDA: new Country({ code: 'UG', name: 'Uganda' }),
  UKRAINE: new Country({ code: 'UA', name: 'Ukraine' }),
  UNITED_ARAB_EMIRATES: new Country({ code: 'AE', name: 'United Arab Emirates' }),
  UNITED_KINGDOM: new Country({ code: 'GB', name: 'United Kingdom' }),
  UNITED_STATES: new Country({ code: 'US', name: 'United States' }),
  UNITED_STATES_MINOR_OUTLYING_ISLANDS: new Country({ code: 'UM', name: 'United States Minor Outlying Islands' }),
  URUGUAY: new Country({ code: 'UY', name: 'Uruguay' }),
  UZBEKISTAN: new Country({ code: 'UZ', name: 'Uzbekistan' }),
  VANUATU: new Country({ code: 'VU', name: 'Vanuatu' }),
  VENEZUELA: new Country({ code: 'VE', name: 'Venezuela' }),
  VIETNAM: new Country({ code: 'VN', name: 'Vietnam' }),
  VIRGIN_ISLANDS_BRITISH: new Country({ code: 'VG', name: 'Virgin Islands British' }),
  VIRGIN_ISLANDS_US: new Country({ code: 'VI', name: 'Virgin Islands US' }),
  WALLIS_AND_FUTUNA: new Country({ code: 'WF', name: 'Wallis and Futuna' }),
  WESTERN_SAHARA: new Country({ code: 'EH', name: 'Western Sahara' }),
  YEMEN: new Country({ code: 'YE', name: 'Yemen' }),
  ZAMBIA: new Country({ code: 'ZM', name: 'Zambia' }),
  ZIMBABWE: new Country({ code: 'ZW', name: 'Zimbabwe' }),

  all: () => Object.values(Countries)
    .filter(country => country instanceof Country)
    .sort(Countries.compareName),

  fromString: (string) => {
    const country = Countries.all().find(country => country.code === string)
    if (!country) {
      throw new Error(`Unknown country: ${string}`)
    }
    return country
  },

  compareName: (l, r) => l.name.localeCompare(r.name)
}
Object.freeze(Countries)

export default Countries
