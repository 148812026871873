import { isBlank } from '@/helpers'

export default class Option {
  constructor (data) {
    if (isBlank(data.label)) {
      throw new Error('Missing option label')
    }
    if (isBlank(data.value)) {
      throw new Error('Missing option value')
    }

    this.label = data.label
    this.value = data.value

    Object.freeze(this)
  }
}
