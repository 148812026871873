export default {
  methods: {
    success () {
      this.$emit('success')
    },

    close () {
      this.$emit('close')
    },

    successClose () {
      this.success()
      this.close()
    }
  }
}
