import LOCALES from '@/locales'

const DEFAULT_LOCALE = 'es'

export const state = () => ({
  drawer: null,

  locale: null,

  query: null,

  scrolled: false
})

export const getters = {
  drawer: state => state.drawer,

  locale: state => state.locale,

  defaultLocale: () => DEFAULT_LOCALE,

  query: state => state.query,

  scrolled: state => state.scrolled
}

export const mutations = {
  setDrawer (state, drawer) {
    state.drawer = drawer
  },

  setLocale (state, locale) {
    state.locale = locale
  },

  setQuery (state, query) {
    state.query = query
  },

  setScrolled (state, scrolled) {
    state.scrolled = scrolled
  }
}

export const actions = {
  async init ({ dispatch }) {
    await dispatch('setLocale')
    await dispatch('initQuery')
    await dispatch('initScrolled')
  },

  setDrawer ({ commit }, drawer) {
    commit('setDrawer', drawer)
  },

  toggleDrawer ({ commit, state }) {
    commit('setDrawer', !state.drawer)
  },

  setLocale ({ commit }, locale) {
    if (!locale || !LOCALES[locale]) {
      locale = DEFAULT_LOCALE
    }
    commit('setLocale', locale)
    this.$setLocale(locale)
  },

  initQuery ({ commit }) {
    const params = {}
    for (const [key, value] of new URLSearchParams(window.location.search)) {
      params[key] = value
    }
    commit('setQuery', params)
  },

  initScrolled ({ state, commit }) {
    window.addEventListener('scroll', () => {
      const scrolled = window.scrollY > 0
      if (state.scrolled !== scrolled) {
        commit('setScrolled', scrolled)
      }
    })
  }
}
