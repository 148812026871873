<template>
  <v-text-field v-model="computedValue" :label="field.label" :solo="field.solo" :dense="field.dense" :required="field.required" :hint="field.hint" :rules="rules" persistent-hint @keydown.enter.native.prevent />
</template>

<script>
export default {
  name: 'TextField',

  props: {
    field: {
      type: Object,
      required: true
    },

    value: {
      type: String,
      default: null
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value ? value.trim() : null)
      }
    },

    rules () {
      const rules = []

      if (this.field.required) {
        rules.push(v => !(!v || !v.trim()) || this.$t('v.fieldRequired'))
      }

      if (this.field.rules) {
        rules.push(...this.field.rules)
      }

      return rules
    }
  }
}
</script>
