<template>
  <v-stepper v-model="step" flat>
    <v-stepper-items>
      <v-stepper-content step="email" class="py-0 px-3">
        <email-password-email-step @next="signinEmailCompleted" />
      </v-stepper-content>

      <v-stepper-content step="password" class="py-0 px-3">
        <email-password-password-step :email="email" @previous="goSignin" @next="signinCompleted" @reset="resetPassword" />
      </v-stepper-content>

      <v-stepper-content step="register" class="py-0 px-3">
        <default-register-step :email="email" @previous="goSignin" @next="registerCompleted" />
      </v-stepper-content>

      <v-stepper-content step="register-disabled" class="py-0 px-3">
        <default-disabled-step @previous="goSignin" @next="close" />
      </v-stepper-content>

      <v-stepper-content step="password-reset" class="py-0 px-3">
        <email-password-reset-password-step :email="email" @previous="goPassword" @next="resetConfirm" />
      </v-stepper-content>

      <v-stepper-content step="password-reset-confirm" class="py-0 px-3">
        <email-password-reset-password-confirm-step @next="closeRefresh" />
      </v-stepper-content>

      <v-stepper-content step="password-reset-change" class="py-0 px-3">
        <email-password-reset-password-change-step @next="resetCompleted" />
      </v-stepper-content>

      <v-stepper-content step="confirm" class="py-0 px-3">
        <default-confirm-step @next="close" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkflowMixin from '../WorkflowMixin'
import EmailPasswordEmailStep from './steps/EmailPasswordEmailStep'
import EmailPasswordPasswordStep from './steps/EmailPasswordPasswordStep'
import EmailPasswordResetPasswordStep from './steps/EmailPasswordResetPasswordStep'
import EmailPasswordResetPasswordConfirmStep from './steps/EmailPasswordResetPasswordConfirmStep'
import EmailPasswordResetPasswordChangeStep from './steps/EmailPasswordResetPasswordChangeStep'
import DefaultRegisterStep from './steps/DefaultRegisterStep'
import DefaultConfirmStep from './steps/DefaultConfirmStep'
import DefaultDisabledStep from './steps/DefaultDisabledStep'

export default {
  components: {
    EmailPasswordEmailStep,
    EmailPasswordPasswordStep,
    EmailPasswordResetPasswordStep,
    EmailPasswordResetPasswordConfirmStep,
    EmailPasswordResetPasswordChangeStep,
    DefaultRegisterStep,
    DefaultConfirmStep,
    DefaultDisabledStep
  },

  mixins: [
    WorkflowMixin
  ],

  props: {
    workflow: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    email: '',
    step: null
  }),

  computed: {
    ...mapGetters({
      project: 'projects/project',
      passwordReset: 'auth/passwordReset'
    })
  },

  created () {
    if (this.passwordReset) {
      this.step = 'password-reset-change'
    } else {
      this.step = 'email'
    }
  },

  methods: {
    goSignin () {
      this.step = 'email'
    },

    goPassword () {
      this.step = 'password'
    },

    signinEmailCompleted ({ email, exists }) {
      this.email = email
      if (exists) {
        this.step = 'password'
      } else if (this.project.registrationEnabled && this.project.form.enabled) {
        this.step = 'register'
      } else {
        this.step = 'register-disabled'
      }
    },

    signinCompleted () {
      this.showMessage(this.$t('v.signInSuccesful'))
      this.successClose()
    },

    registerCompleted () {
      this.success()
      this.step = 'confirm'
    },

    resetPassword () {
      this.step = 'password-reset'
    },

    resetConfirm () {
      this.step = 'password-reset-confirm'
    },

    closeRefresh () {
      let search = window.location.search
      search += search ? '&' : '?'
      search += `e=${Buffer.from(this.email).toString('base64')}`
      window.location.replace(window.location.pathname + search + window.location.hash)
    },

    resetCompleted ({ email }) {
      this.email = email
      this.step = 'password'
    },

    ...mapActions({
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
.v-dialog .v-stepper.theme--dark, .v-dialog .v-stepper.theme--light {
  background-color: transparent;
  box-shadow: none;
}
</style>
