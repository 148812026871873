import Option from './Option'
import { isBlank, shuffle } from '@/helpers'

export const QuestionTypeMode = Object.freeze({
  DEFAULT: null,
  ROW: 'row',
  COLUMN: 'column',

  all: () => Object.values(QuestionTypeMode).filter(mode => typeof mode === 'string'),

  fromString: (string) => {
    const mode = QuestionTypeMode.all().find(mode => mode === string)
    if (!mode) {
      throw new Error(`Unknown question type mode: ${string}`)
    }
    return mode
  }
})

export class Question {
  constructor (data) {
    if (isBlank(data.id)) {
      throw new Error('Missing question ID')
    }
    if (isBlank(data.text)) {
      throw new Error('Missing question text')
    }

    this.id = data.id
    this.text = data.text
    this.subtext = data.subtext
    this.required = !!data.required
  }
}

export class SingleChoiceQuestion extends Question {
  constructor (data) {
    super(data)
    if (!data.options) {
      throw new Error('Missing single choice question options')
    }

    this.random = !!data.random
    this.options = data.options.map(optionData => new Option(optionData))
    this.typeMode = data.typeMode

    if (this.random) {
      this.options = shuffle(this.options)
    }

    Object.freeze(this)
  }

  get row () {
    return this.typeMode === QuestionTypeMode.ROW
  }

  get column () {
    return this.typeMode === QuestionTypeMode.COLUMN
  }
}

export class MultipleChoiceQuestion extends Question {
  constructor (data) {
    super(data)
    if (!data.options) {
      throw new Error('Missing multiple choice question options')
    }

    this.random = !!data.random
    this.options = data.options.map(optionData => new Option(optionData))

    if (this.random) {
      this.options = shuffle(this.options)
    }

    Object.freeze(this)
  }
}

export class OpenQuestion extends Question {}
