<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col v-if="project.form.registrationDisabledDescription" cols="12" v-html="project.form.registrationDisabledDescription" />
        <v-col v-else cols="12">
          {{ $t('v.registrationDisabled') }}
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="auto">
          <v-btn :small="project.form.small" nuxt text @click="previous">
            {{ $t('v.back') }}
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn large :small="project.form.small" color="accent" class="px-11" @click="next">
            {{ $t('v.continue') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import StepMixin from './StepMixin'

export default {
  mixins: [
    StepMixin
  ],

  computed: {
    ...mapGetters({
      project: 'projects/project'
    })
  }
}
</script>

<style scoped>
</style>
