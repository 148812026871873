import ohm from 'ohm-js'

export const conditionGrammar = ohm.grammar(String.raw`
Expression {
  Expr
    = OrExpr

  OrExpr
    = OrExpr "||" AndExpr  --or
    | AndExpr

  AndExpr
    = AndExpr "&&" CompExpr  --and
    | CompExpr

  CompExpr
    = CompExpr "==" AddExpr --eq
    | CompExpr ">" AddExpr --gt
    | CompExpr "<" AddExpr --lt
    | CompExpr ">=" AddExpr --gte
    | CompExpr "<=" AddExpr --lte
    | AddExpr

  AddExpr
    = AddExpr "+" MulExpr --add
    | AddExpr "-" MulExpr --substract
    | MulExpr

  MulExpr
    = MulExpr "*" PriExpr --multiply
    | MulExpr "/" PriExpr --divide
    | PriExpr

  PriExpr
    = "(" Expr ")" --paren
    | "!" PriExpr --negation
    | "-" PriExpr --inversion
    | path "has" PriExpr --has
    | FunExpr
    | value
    | path

  FunExpr
    = "number" "(" Expr ")" --number
    | "default" "(" Expr "," Expr ")" --default
    | "min" "(" Expr "," Expr ")" --min
    | "max" "(" Expr "," Expr ")" --max

  path
    = letter (alnum | "_" | "-")* ("." (alnum | "_" | "-")+)*

  value
    = number
    | const
    | string

  const
    = "true" --true
    | "false" --false

  string
    = "\"" (~"\"" any)* "\""

  number
    = digit* "." digit+ --fraction
    | digit+ --whole
    | "Infinity" --infinity
}
`)

export class ParsingError extends Error {
  constructor (message) {
    super(message.replace(/^[^|]+\| /, ''))

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ParsingError)
    }

    this.name = 'ParsingError'
  }
}

export function parseCondition (condition) {
  if (!condition) {
    return null
  }

  const match = conditionGrammar.match(condition)

  if (match.failed()) {
    throw new ParsingError(match.message)
  }

  return match
}
