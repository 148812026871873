<template>
  <v-stepper v-model="step" flat>
    <v-stepper-items>
      <v-stepper-content step="email" class="py-0 px-3">
        <sn-signin-email-step @next="signinEmailCompleted" />
      </v-stepper-content>

      <v-stepper-content step="password" class="py-0 px-3">
        <sn-signin-password-step :email="email" @previous="goSignin" @next="signinCompleted" @reset="resetPassword" />
      </v-stepper-content>

      <v-stepper-content step="register" class="py-0 px-3">
        <sn-register-step :email="email" @previous="goSignin" @next="registerCompleted" />
      </v-stepper-content>

      <v-stepper-content step="register-disabled" class="py-0 px-3">
        <default-disabled-step @previous="goSignin" @next="close" />
      </v-stepper-content>

      <v-stepper-content step="password-reset" class="py-0 px-3">
        <sn-reset-password-step :email="email" @previous="goPassword" @next="resetCompleted" />
      </v-stepper-content>

      <v-stepper-content step="password-reset-reenter" class="py-0 px-3">
        <sn-reset-password-reenter-step :email="email" @previous="goReset" @next="reenterCompleted" />
      </v-stepper-content>

      <v-stepper-content step="confirm" class="py-0 px-3">
        <default-confirm-step @next="close" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import WorkflowMixin from '../WorkflowMixin'
import SnSigninEmailStep from './steps/SnSigninEmailStep'
import SnSigninPasswordStep from './steps/SnSigninPasswordStep'
import SnRegisterStep from './steps/SnRegisterStep'
import SnResetPasswordStep from './steps/SnResetPasswordStep'
import SnResetPasswordReenterStep from './steps/SnResetPasswordReenterStep'
import DefaultDisabledStep from './steps/DefaultDisabledStep'
import DefaultConfirmStep from './steps/DefaultConfirmStep'

export default {
  components: {
    SnSigninEmailStep,
    SnSigninPasswordStep,
    SnRegisterStep,
    SnResetPasswordStep,
    SnResetPasswordReenterStep,
    DefaultDisabledStep,
    DefaultConfirmStep
  },

  mixins: [
    WorkflowMixin
  ],

  props: {
    workflow: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    email: '',
    step: 'email'
  }),

  computed: {
    ...mapGetters({
      project: 'projects/project'
    })
  },

  methods: {
    goSignin () {
      this.step = 'email'
    },

    goPassword () {
      this.step = 'password'
    },

    goReset () {
      this.step = 'password-reset'
    },

    signinEmailCompleted ({ email, check }) {
      this.email = email
      if (check.exists && !check.incomplete) {
        this.step = 'password'
      } else if (this.project.registrationEnabled && this.project.form.enabled) {
        this.step = 'register'
      } else {
        this.step = 'register-disabled'
      }
    },

    signinCompleted () {
      this.showMessage(this.$t('v.signInSuccesful'))
      this.successClose()
    },

    registerCompleted () {
      this.success()
      this.step = 'confirm'
    },

    resetPassword () {
      this.step = 'password-reset'
    },

    resetCompleted () {
      this.step = 'password-reset-reenter'
    },

    reenterCompleted () {
      this.showMessage(this.$t('v.signInSuccesful'))
      this.successClose()
    },

    ...mapActions({
      showMessage: 'notification/showMessage',
      showError: 'notification/showError'
    })
  }
}
</script>

<style scoped>
.v-dialog .v-stepper.theme--dark, .v-dialog .v-stepper.theme--light {
  background-color: transparent;
  box-shadow: none;
}
</style>
