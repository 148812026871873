<template>
  <v-dialog :value="loginDialog" overlay-opacity="0.8" max-width="600px" @input="close">
    <iframe v-if="loginDialog" :src="authURL" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loginDialog: 'auth/loginDialog',
      authURL: 'auth/authURL',
      site: 'sites/site'
    })
  },

  mounted () {
    window.addEventListener('message', (event) => {
      if (event.data === 'signinClose') {
        this.close()
      } else if (event.data === 'successfulRegistration') {
        this.successfulRegistration()
      }
    })
  },

  methods: {
    async close () {
      try {
        this.hideLoginDialog()
        this.initProjectUser()
        await this.refreshSite()
      } catch (error) {
        this.$handleError(error)
      }
    },

    ...mapActions({
      hideLoginDialog: 'auth/hideLoginDialog',
      initProjectUser: 'auth/initProjectUser',
      refreshSite: 'sites/refreshSite',
      successfulRegistration: 'analytics/successfulRegistration'
    })
  }
}
</script>

<style scoped>
iframe {
  height: 600px;
  border: none;
  background-color: white;
}
</style>
