import * as Sentry from '@sentry/browser'
import { AuthError, NotFoundError } from '@/models/errors'

export default ({ error, redirect }, inject) => {
  inject('handleError', (e, params = {}) => {
    if (e instanceof NotFoundError) {
      error({ statusCode: 404, message: e.message })
      return
    } else if (e instanceof AuthError) {
      redirect('/pass', { ...params, statusCode: e.status })
      return
    }

    // eslint-disable-next-line no-console
    console.error(e)
    Sentry.captureException(e)

    if (e.response && e.response.status) {
      error({ message: e.message, statusCode: e.response.status })
    } else {
      error({ message: e.message })
    }
  })

  inject('logError', (e) => {
    // eslint-disable-next-line no-console
    console.error(e)
  })
}
