import { omit, keysRemoveTrailingUnderscore } from '@/helpers'

export default class UserDots {
  constructor (data = {}) {
    this._id = data.id
    this._modified = data.modified
    this._uid = data.uid

    Object.assign(this, omit(keysRemoveTrailingUnderscore(data), 'id', 'modified', 'uid'))
  }

  get id () {
    return this._id
  }

  get uid () {
    return this._uid
  }

  get modified () {
    return this._created
  }
}
