<template>
  <div class="d-flex">
    <input name="username" :value="email" autocomplete="username" type="text" class="hidden">
    <v-text-field v-model="computedValue" :type="showPassword ? 'text' : 'password'" :label="field.label" :solo="field.solo" :dense="field.dense" :required="field.required" :hint="field.hint" :rules="rules" autocomplete="new-password" persistent-hint @keydown.enter.native.prevent>
      <template #append>
        <v-btn icon tabindex="-1" @click="showPassword = !showPassword">
          <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
        </v-btn>
      </template>
    </v-text-field>
    <v-text-field v-if="field.confirmLabel" ref="confirm" v-model="confirm" :type="showPasswordConfirm ? 'text' : 'password'" :label="field.confirmLabel" :solo="field.solo" :dense="field.dense" :required="field.required" :hint="field.hint" :rules="confirmRules" autocomplete="off" persistent-hint class="ml-6" @keydown.enter.native.prevent>
      <template #append>
        <v-btn icon tabindex="-1" @click="showPasswordConfirm = !showPasswordConfirm">
          <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'TextField',

  props: {
    field: {
      type: Object,
      required: true
    },

    email: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      showPassword: false,
      confirm: '',
      showPasswordConfirm: false
    }
  },

  computed: {
    computedValue: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value || null)

        const confirm = this.$refs.confirm
        if (confirm && this.confirm === value) {
          confirm.resetValidation()
        }
      }
    },

    rules () {
      const rules = []

      if (this.field.required) {
        rules.push(v => !(!v || !v.trim()) || this.$t('v.fieldRequired'))
      }

      if (this.field.rules) {
        rules.push(...this.field.rules)
      }

      return rules
    },

    confirmRules () {
      return [
        v => v === this.value || this.$t('v.passwordMismatch')
      ]
    }
  }
}
</script>
