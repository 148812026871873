import Vue from 'vue'
import { getFirestore, doc, getDoc } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { Q } from '@/models/qs/Q'
import Reply from '@/models/qs/Reply'
import { SingleChoiceQuestion, MultipleChoiceQuestion, OpenQuestion } from '@/models/qs/Question.js'

export const state = () => ({
  qs: {}
})

export const getters = {
  q: state => qId => state.qs[qId],

  replyCountQ: (state, getters, rootState, rootGetters) => (q) => {
    return rootGetters['userdots/conditionContext'].qs?.[q.id]?.replyCount || 0
  },

  maxPointsQ: (state, getters, rootState, rootGetters) => (q) => {
    return rootGetters['userdots/conditionContext'].qs?.[q.id]?.maxPoints || 0
  }
}

export const mutations = {
  updateQ (state, { id, q }) {
    Vue.set(state.qs, id, q)
  }
}

function dataToQ (id, data) {
  const questions = (data.questions || [])
    .map(questionData => dataToQuestion({ random: data.random, ...questionData }))
    .filter(question => question)
  return new Q({ ...data, id, questions })
}

function dataToQuestion (data) {
  switch (data.type) {
    case 'radio':
      return new SingleChoiceQuestion(data)
    case 'check':
      return new MultipleChoiceQuestion(data)
    case 'text':
      return new OpenQuestion(data)
    default:
      console.warn(`Ignoring unknown question type: ${data.type}`) // eslint-disable-line no-console
  }
}

function dataToReply (id, data) {
  const created = data.created ? (typeof data.created === 'string' ? new Date(data.created) : data.created.toDate()) : null
  const modified = data.modified ? (typeof data.modified === 'string' ? new Date(data.modified) : data.modified.toDate()) : null
  return new Reply({ ...data, id, created, modified })
}

export const actions = {
  async initQ ({ commit, getters, dispatch, rootGetters }, qId) {
    const projectId = rootGetters['projects/projectId']

    if (!projectId || !qId) {
      return
    }

    const d = await getDoc(doc(getFirestore(), 'projects', projectId, 'qs', qId))
    if (!d.exists()) {
      return null
    }

    const q = dataToQ(qId, d.data())
    commit('updateQ', { id: qId, q })

    return q
  },

  async replyQ ({ commit, rootGetters }, { q, replies }) {
    const projectId = rootGetters['projects/projectId']
    const user = rootGetters['auth/user']
    if (!projectId || !user) {
      return
    }

    const result = await httpsCallable(getFunctions(), 'replyQ')({ projectId, qId: q.id, replies })

    return dataToReply(result.data.id, result.data)
  },

  async replyQUpdate ({ commit, rootGetters }, { q, replies }) {
    const projectId = rootGetters['projects/projectId']
    const user = rootGetters['auth/user']
    if (!projectId || !user) {
      return
    }

    const result = await httpsCallable(getFunctions(), 'replyQUpdate')({ projectId, qId: q.id, replies })

    return dataToReply(result.data.id, result.data)
  }
}
