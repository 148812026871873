import * as moment from 'moment'

export default ({ _ }, inject) => {
  inject('formatDate', (timestamp) => {
    if (!timestamp) {
      return null
    }
    return moment(timestamp).local().format('MMMM DD, YYYY')
  })

  inject('formatTime', (timestamp) => {
    if (!timestamp) {
      return null
    }
    return moment(timestamp).local().format('HH:mm')
  })
}
