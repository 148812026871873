import { initializeApp, getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, initializeFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

export default () => {
  const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG)
  initializeApp(firebaseConfig)

  initializeFirestore(getApp(), { experimentalForceLongPolling: true })

  if (!process.env.FIREBASE_EMULATOR_DISABLED) {
    connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
    connectFunctionsEmulator(getFunctions(), 'localhost', 5000)
    connectAuthEmulator(getAuth(), 'http://localhost:9099', { disableWarnings: true })
  }
}
