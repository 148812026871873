export default class Connection {
  constructor (data = {}) {
    this.id = data.id

    this.uid = data.uid
    this.email = data.email

    this.created = data.created

    Object.freeze(this)
  }
}
