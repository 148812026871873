import 'moment/locale/es'
import { es as esDefault, en as enDefault, zhHans as zhDefault } from 'vuetify/lib/locale'

const LOCALES = {
  en: {
    ...enDefault,

    v: {
      logout: 'Logout',
      send: 'Send',
      siteCode: 'Code',
      enterSiteCode: 'Enter site code to continue',
      siteCodeInvalid: 'Invalid code',
      password: 'Password',
      passwordInvalid: 'Password invalid',
      register: 'Register',
      registrationConfirmation: 'You have been registered for the live broadcast.',
      forgotPassword: 'Forgot password?',
      recoverPassword: 'Recover my password',
      resetPassword: 'Change your password',
      resetPasswordDescription: 'By clicking reset my password, you\'ll receive an email with a link to reset your password.',
      resetPasswordDescriptionSn: 'By clicking reset my password, you\'ll receive an email with your new password.',
      resetMyPassword: 'Reset my password',
      resetPasswordError: 'Error during password reset',
      resetPasswordErrorExpired: 'Password reset code expired, please reset your password again',
      resetPasswordErrorInvalid: 'Password reset code invalid',
      resetPasswordErrorUserDisabled: 'Can\'t reset password: user disabled',
      resetPasswordErrorUserNotFound: 'Can\'t reset password: user not found',
      resetPasswordErrorWeakPassword: 'Your new password is too weak, please choose a different one',
      checkPasswordEmail: 'Check your inbox',
      checkPasswordEmailDescription: 'Please open the link in the reset password email to reset your password.',
      checkPasswordEmailDescriptionSn: 'Your password has been reset and emailed to you, now you can get access with your new password.',
      changePassword: 'Reset password',
      changePasswordDescription: 'Please enter your new password',
      changePasswordSuccess: 'Password changed successfully',
      unknownError: 'Unknown error',
      deadlineExceededError: 'This operation took too long, please try again later',
      user: 'User',
      date: 'Date',
      time: 'Time',
      subject: 'Subject',
      submit: 'Submit',
      submitting: 'Submitting',
      justSubmitted: 'Submitted!',
      writeComment: 'Write your question or comment',
      submitCommentError: 'Error submitting comment',
      back: 'Back',
      continue: 'Continue',
      close: 'Close',
      retry: 'Retry',
      enterEmail: 'Enter your email',
      signIn: 'Sign-in',
      signInSuccesful: 'Sign-in succesful',
      fieldRequired: '* Required',
      emailRequired: 'Email required',
      emailInvalid: 'Email invalid',
      phoneInvalid: 'Phone number invalid',
      passwordMismatch: 'Passwords do not match',
      registrationDisabled: 'Registration for this event has been closed.',
      generateDiploma: 'Generate certificate!',
      generateCertificate: 'Generate certificate',
      fullName: 'Full name',
      fullNameRequired: 'Full name required',
      enterFullNameForDiploma: 'Write your full name, how you want it to appear on your certificate',
      previous: 'Previous',
      next: 'Next',
      finish: 'Finish',
      reachedAttemptLimit: 'You have reached the maximum number of attempts',
      tryAgain: 'Try again',
      qSent: 'Poll sent, thank you for your participation!',
      qSendError: 'Error sending reply, please check your internet connection and try again',
      qSuccess: 'Congratulations, your final score is {0} points.',
      qFail: 'Your final score is {0} points.',
      qRetry: 'Your final score is {0} points. Please try again.',
      qFieldRequired: 'Required to continue',
      disclaimer: 'Disclaimer',
      disclaimerAccept: 'I agree',
      gender: {
        M: 'male',
        F: 'female'
      },
      specialty: {
        allergist: 'Allergist',
        algologist: 'Algologist',
        pathological_anat: 'Pathological Anat.',
        angiologist: 'Angiologist',
        bariatrician: 'Bariatrician',
        cardiologist: 'Cardiologist',
        maxillofacial_surgeon: 'Maxillofacial Surg.',
        rhinologist_surgeon: 'Rhinologist Surg.',
        transplantologist_surgeon: 'Transplantologist Surg.',
        surgeon: 'Surgeon',
        dental_surgeon: 'Dental Surgeon',
        gastroenterologist_surgeon: 'Gastroenterologist Surgeon',
        obstetric_surgeon: 'Obstetric Surgeon',
        oncologist_surgeon: 'Oncologist Surgeon',
        plastic_surgeon: 'Plastic Surgeon',
        vascular_surgeon: 'Vascular Surgeon',
        coloproctologist: 'Coloproctologist',
        dermatologist: 'Dermatologist',
        endocrinologist: 'Endocrinologist',
        nurse: 'Nurse',
        epidemiologist: 'Epidemiologist',
        physiologist: 'Physiologist',
        physiotherapist: 'Physiotherapist',
        gastroenterologists: 'Gastroenterologists',
        genetics: 'Genetics',
        geriatrician: 'Geriatrician',
        gynecologist: 'Gynecologist',
        gynecologist_oncologist: 'Gynecologist Oncologist',
        hematologist: 'Hematologist',
        homeopath: 'Homeopath',
        infectologist: 'Infectologist',
        immunologist: 'Immunologist',
        intensivist: 'Intensivist',
        respiratory_therapist: 'Respiratory Therapist',
        integrated_medicine: 'Integrated Medicine',
        legal_medicine: 'Legal Medicine',
        nuclear_medicine: 'Nuclear Medicine',
        family_doctor: 'Family Doctor',
        general_physician: 'General Physician',
        internist_doctor: 'Internist Doctor',
        sports_medicine_physician: 'Sports Medicine Physician',
        nephrologist: 'Nephrologist',
        neonatologist: 'Neonatologist',
        pulmonologist: 'Pulmonologist',
        neurosurgeon: 'Neurosurgeon',
        neurologist: 'Neurologist',
        nutrition: 'Nutrition',
        obstetrician: 'Obstetrician',
        odontologist: 'Odontologist',
        ophthalmologist: 'Ophthalmologist',
        oncologist: 'Oncologist',
        orthopedist_traumatologist: 'Orthopedist Traumatologist',
        otolaryngologist: 'Otolaryngologist',
        pediatrician: 'Pediatrician',
        chiropodist: 'Chiropodist',
        proctologist: 'Proctologist',
        psychiatrist: 'Psychiatrist',
        radiologist: 'Radiologist',
        radiotherapist: 'Radiotherapist',
        rehabilitation: 'Rehabilitation',
        rheumatologist: 'Rheumatologist',
        sexologist: 'Sexologist',
        intensive_therapy: 'Intensive Therapy',
        transplantologist: 'Transplantologist',
        emergency_physician: 'Emergency Physician',
        urologist: 'Urologist',
        respiratory_tracts: 'Respiratory Tracts'
      }
    }
  },

  es: {
    ...esDefault,

    v: {
      logout: 'Cerrar sesión',
      send: 'Enviar',
      siteCode: 'Código',
      enterSiteCode: 'Introduzca el código del sitio para continuar',
      siteCodeInvalid: 'Código incorrecto',
      password: 'Contraseña',
      passwordInvalid: 'Contraseña incorrecta',
      register: 'Registro',
      registrationConfirmation: 'Usted ha quedado registrado para la transmisión en vivo.',
      forgotPassword: '¿Olvidó su contraseña?',
      recoverPassword: 'Recuperar mi contraseña',
      resetPassword: 'Restablecer su contraseña',
      resetPasswordDescription: 'By clicking reset my password, you\'ll receive an email with a link to reset your password.',
      resetPasswordDescriptionSn: 'By clicking reset my password, you\'ll receive an email with your new password.',
      resetMyPassword: 'Recuperar contraseña',
      resetPasswordError: 'Error durante el reinicio de contraseña',
      resetPasswordErrorExpired: 'Password reset code expired, please reset your password again',
      resetPasswordErrorInvalid: 'Password reset code invalid',
      resetPasswordErrorUserDisabled: 'Can\'t reset password: user disabled',
      resetPasswordErrorUserNotFound: 'Can\'t reset password: user not found',
      resetPasswordErrorWeakPassword: 'Your new password is too weak, please choose a different one',
      checkPasswordEmail: 'Check your inbox',
      checkPasswordEmailDescription: 'Please open the link in the reset password email to reset your password.',
      checkPasswordEmailDescriptionSn: 'Your password has been reset and emailed to you, now you can get access with your new password.',
      changePassword: 'Reset password',
      changePasswordDescription: 'Please enter your new password',
      changePasswordSuccess: 'Password changed successfully',
      unknownError: 'Error desconocido',
      deadlineExceededError: 'La operación solicitada está llevando más tiempo de lo esperado, por favor intente más tarde',
      user: 'Usuario',
      date: 'Fecha',
      time: 'Hora',
      subject: 'Tema',
      submit: 'Enviar',
      submitting: 'Enviando',
      justSubmitted: '!Enviado!',
      writeComment: 'Escriba su pregunta o comentario',
      submitCommentError: 'Error al enviar el comentario',
      back: 'Regresar',
      continue: 'Continuar',
      close: 'Cerrar',
      retry: 'Reintentar',
      enterEmail: 'Ingresar Email',
      signIn: 'Iniciar sesión',
      signInSuccesful: 'Inicio de sesión exitoso',
      fieldRequired: '* Requerido',
      emailRequired: 'Email requerido',
      emailInvalid: 'Email no válido',
      phoneInvalid: 'Numero de telefono invalido',
      passwordMismatch: 'Las contraseñas no coinciden',
      registrationDisabled: 'El Registro para este evento se ha cerrado.',
      generateDiploma: '¡Generar Constancia!',
      generateCertificate: 'Generar constancia',
      fullName: 'Nombre completo',
      fullNameRequired: 'Nombre completo requerido',
      enterFullNameForDiploma: 'Escriba su nombre completo, cómo desea que aparezca en su constancia',
      previous: 'Anterior',
      next: 'Siguiente',
      finish: 'Finalizar',
      reachedAttemptLimit: 'Ha alcanzado el límite de intentos',
      tryAgain: 'Intentar nuevamente',
      qSent: '¡Encuesta enviada, gracias por su participación!',
      qSendError: 'Error al enviar respuesta, por favor revise su conexión a internet e intente nuevamente',
      qSuccess: 'Felicitaciones, su calificación final es {0} puntos.',
      qFail: 'Su calificación final es {0} puntos.',
      qRetry: 'Su calificación final es {0} puntos. Por favor intente de nuevo.',
      qFieldRequired: 'Requerido para continuar',
      disclaimer: 'Disclaimer',
      disclaimerAccept: 'I agree',
      gender: {
        M: 'masculino',
        F: 'femenino'
      },
      specialty: {
        allergist: 'Alergólogo',
        algologist: 'Algologo',
        pathological_anat: 'Anat. Patológica',
        angiologist: 'Angiólogo',
        bariatrician: 'Bariatra',
        cardiologist: 'Cardiólogo',
        maxillofacial_surgeon: 'Cir. Maxilofacial',
        rhinologist_surgeon: 'Cir. Rerinólogo',
        transplantologist_surgeon: 'Cir. Transplantólogo',
        surgeon: 'Cirujano',
        dental_surgeon: 'Cirujano Dentista',
        gastroenterologist_surgeon: 'Cirujano Gastroenterólogo',
        obstetric_surgeon: 'Cirujano Obstetra',
        oncologist_surgeon: 'Cirujano Oncólogo',
        plastic_surgeon: 'Cirujano Plástico',
        vascular_surgeon: 'Cirujano Vascular',
        coloproctologist: 'Coloproctólogo',
        dermatologist: 'Dermatólogo',
        endocrinologist: 'Endocrinólogo',
        nurse: 'Enfermera',
        epidemiologist: 'Epidemiólogo',
        physiologist: 'Fisiólogo',
        physiotherapist: 'Fisioterapeuta',
        gastroenterologists: 'Gastroenterólogos',
        genetics: 'Genética',
        geriatrician: 'Geriatra',
        gynecologist: 'Ginecólogo',
        gynecologist_oncologist: 'Ginecólogo Oncólogo',
        hematologist: 'Hematólogo',
        homeopath: 'Homeópata',
        infectologist: 'Infectólogo',
        immunologist: 'Inmunólogo',
        intensivist: 'Intensivista',
        respiratory_therapist: 'MDTR',
        integrated_medicine: 'Medicina Integrada',
        legal_medicine: 'Medicina Legal',
        nuclear_medicine: 'Medicina Nuclear',
        family_doctor: 'Médico Familiar',
        general_physician: 'Médico General',
        internist_doctor: 'Médico Intrenista',
        sports_medicine_physician: 'Médico del deporte',
        nephrologist: 'Nefrólogo',
        neonatologist: 'Neonatólogo',
        pulmonologist: 'Neumólogo',
        neurosurgeon: 'Neurocirujano',
        neurologist: 'Neurólogo',
        nutrition: 'Nutrición',
        obstetrician: 'Obstetra',
        odontologist: 'Odontólogo',
        ophthalmologist: 'Oftalmólogo',
        oncologist: 'Oncólogo',
        orthopedist_traumatologist: 'Ortopedista Traumatólogo',
        otolaryngologist: 'Otorrinolaringólogo',
        pediatrician: 'Pediatra',
        chiropodist: 'Podólogo',
        proctologist: 'Proctólogo',
        psychiatrist: 'Psiquiatra',
        radiologist: 'Radiólogo',
        radiotherapist: 'Radioterapeuta',
        rehabilitation: 'Rehabilitación',
        rheumatologist: 'Reumatólogo',
        sexologist: 'Sexólogo',
        intensive_therapy: 'Terapia Intensiva',
        transplantologist: 'Transplantólogo',
        emergency_physician: 'Urgenciólogo',
        urologist: 'Urólogo',
        respiratory_tracts: 'Vías Respiratorias'
      }
    }
  },

  zh: {
    ...zhDefault,

    v: {
      logout: 'Logout',
      send: 'Send',
      siteCode: 'Code',
      enterSiteCode: 'Enter site code to continue',
      siteCodeInvalid: 'Invalid code',
      password: 'Password',
      passwordInvalid: 'Password invalid',
      register: 'Register',
      registrationConfirmation: 'You have been registered for the live broadcast.',
      forgotPassword: 'Forgot password?',
      recoverPassword: 'Recover my password',
      resetPassword: 'Change your password',
      resetPasswordDescription: 'By clicking reset my password, you\'ll receive an email with a link to reset your password.',
      resetPasswordDescriptionSn: 'By clicking reset my password, you\'ll receive an email with your new password.',
      resetMyPassword: 'Reset my password',
      resetPasswordError: 'Error during password reset',
      resetPasswordErrorExpired: 'Password reset code expired, please reset your password again',
      resetPasswordErrorInvalid: 'Password reset code invalid',
      resetPasswordErrorUserDisabled: 'Can\'t reset password: user disabled',
      resetPasswordErrorUserNotFound: 'Can\'t reset password: user not found',
      resetPasswordErrorWeakPassword: 'Your new password is too weak, please choose a different one',
      checkPasswordEmail: 'Check your inbox',
      checkPasswordEmailDescription: 'Please open the link in the reset password email to reset your password.',
      checkPasswordEmailDescriptionSn: 'Your password has been reset and emailed to you, now you can get access with your new password.',
      changePassword: 'Reset password',
      changePasswordDescription: 'Please enter your new password',
      changePasswordSuccess: 'Password changed successfully',
      unknownError: 'Unknown error',
      deadlineExceededError: 'This operation took too long, please try again later',
      user: 'User',
      date: 'Date',
      time: 'Time',
      subject: 'Subject',
      submit: 'Submit',
      submitting: 'Submitting',
      justSubmitted: 'Submitted!',
      writeComment: 'Write your question or comment',
      submitCommentError: 'Error submitting comment',
      back: '返回',
      continue: 'Continue',
      close: 'Close',
      retry: 'Retry',
      enterEmail: '输入邮箱',
      signIn: 'Sign-in',
      signInSuccesful: 'Sign-in succesful',
      fieldRequired: '* Required',
      emailRequired: '邮箱为必填项',
      emailInvalid: 'Email invalid',
      phoneInvalid: 'Phone number invalid',
      passwordMismatch: 'Passwords do not match',
      registrationDisabled: 'Registration for this event has been closed.',
      generateDiploma: 'Generate certificate!',
      generateCertificate: 'Generate certificate',
      fullName: 'Full name',
      fullNameRequired: 'Full name required',
      enterFullNameForDiploma: 'Write your full name, how you want it to appear on your certificate',
      previous: 'Previous',
      next: 'Next',
      finish: 'Finish',
      reachedAttemptLimit: 'You have reached the maximum number of attempts',
      tryAgain: 'Try again',
      qSent: 'Poll sent, thank you for your participation!',
      qSendError: 'Error sending reply, please check your internet connection and try again',
      qSuccess: 'Congratulations, your final score is {0} points.',
      qFail: 'Your final score is {0} points.',
      qRetry: 'Your final score is {0} points. Please try again.',
      qFieldRequired: 'Required to continue',
      disclaimer: 'Disclaimer',
      disclaimerAccept: 'I agree',
      gender: {
        M: 'male',
        F: 'female'
      },
      specialty: {
        allergist: '变态（过敏）反应学专家',
        algologist: '疼痛学专家',
        pathological_anat: '病理解剖学专家',
        angiologist: '血管学专家',
        bariatrician: '肥胖症专家',
        cardiologist: '心脏病学专家',
        maxillofacial_surgeon: '颌面部外科专家',
        rhinologist_surgeon: '鼻窦外科专家',
        transplantologist_surgeon: '移植外科学专家',
        surgeon: '外科专家',
        dental_surgeon: '牙外科专家',
        gastroenterologist_surgeon: '胃肠外科学专家',
        obstetric_surgeon: '产科外科专家',
        oncologist_surgeon: '肿瘤外科专家',
        plastic_surgeon: '整形外科专家',
        vascular_surgeon: '血管外科专家',
        coloproctologist: '肛肠病学专家',
        dermatologist: '皮肤病专家',
        endocrinologist: '内分泌学专家',
        nurse: '护士',
        epidemiologist: '流行病学专家',
        physiologist: '生理学专家',
        physiotherapist: '物理治疗专家',
        gastroenterologists: '胃肠学专家',
        genetics: '遗传学专家',
        geriatrician: '老年病学专家',
        gynecologist: '妇科专家',
        gynecologist_oncologist: '妇科肿瘤专家',
        hematologist: '血液学专家',
        homeopath: '顺势疗法专家',
        infectologist: '感染学专家',
        immunologist: '免疫学专家',
        intensivist: '重症加护专家',
        respiratory_therapist: '呼吸治疗专家',
        integrated_medicine: '整合医学专家',
        legal_medicine: '法医学专家',
        nuclear_medicine: '核医学专家',
        family_doctor: '家庭医生',
        general_physician: '全科医生',
        internist_doctor: '内科医生',
        sports_medicine_physician: '运动医学专家',
        nephrologist: '肾病学专家',
        neonatologist: '新生儿学专家',
        pulmonologist: '胸肺科专家',
        neurosurgeon: '神经外科专家',
        neurologist: '神经科专家',
        nutrition: '营养学家',
        obstetrician: '产科专家',
        odontologist: '牙科专家',
        ophthalmologist: '眼科专家',
        oncologist: '肿瘤学专家',
        orthopedist_traumatologist: '创伤矫形学专家',
        otolaryngologist: '耳鼻喉科专家',
        pediatrician: '儿科专家',
        chiropodist: '足科专家',
        proctologist: '直肠病专家',
        psychiatrist: '精神科专家',
        radiologist: '放射科专家',
        radiotherapist: '放射疗法专家',
        rehabilitation: '康复学专家',
        rheumatologist: '风湿病学专家',
        sexologist: '性学专家',
        intensive_therapy: '强化治疗',
        transplantologist: '移植学专家',
        emergency_physician: '急诊医师',
        urologist: '泌尿科专家',
        respiratory_tracts: '呼吸道专家'
      }
    }
  }
}

export default LOCALES
