import Vue from 'vue'
import { getFirestore, doc, getDoc, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { subscribeOne } from '@/helpers'
import Project from '@/models/Project'
import Session from '@/models/Session'
import Connection from '@/models/Connection'

const MAX_COMMENT_LENGTH = 290

export const state = () => ({
  projectId: null,

  project: null,

  sessions: {},

  connected: false,

  hiddenSessionNotifications: {},

  meetingUrl: null
})

function getHiddenSessionNotificationId ({ uid, sessionId, notificationId }) {
  const id = `${uid}-${sessionId}-${notificationId}`
  return id
}

export const getters = {
  projectId: state => state.projectId,

  project: state => state.project,

  session: state => id => state.sessions[id],

  siteSession: (state) => {
    const sessions = Object.values(state.sessions)

    if (sessions.length !== 1) {
      return null
    }

    return sessions[0]
  },

  maxCommentLength: () => MAX_COMMENT_LENGTH,

  sessionNotificationVisible: (state, getters, rootState, rootGetters) => (session) => {
    const user = rootGetters['auth/user']
    const notification = session && session.notification
    if (!user || !notification) {
      return false
    }

    const id = getHiddenSessionNotificationId({ uid: user.uid, sessionId: session.id, notificationId: notification.publicId })
    if (state.hiddenSessionNotifications[id]) {
      return false
    }

    if (notification.meetingId && !state.meetingUrl) {
      return false
    }

    const evaluateCondition = rootGetters['userdots/evaluateCondition']

    if (evaluateCondition('!loaded')) {
      return false
    }

    if (!evaluateCondition(notification.condition)) {
      return false
    }

    return true
  },

  meetingUrl: state => state.meetingUrl
}

export const mutations = {
  setProjectId (state, projectId) {
    state.projectId = projectId
  },

  setProject (state, project) {
    state.project = project
  },

  clearSessions (state) {
    state.sessions = {}
  },

  updateSession (state, { id, session }) {
    Vue.set(state.sessions, id, session)
  },

  toggleConnected (state) {
    state.connected = !state.connected
  },

  hideSessionNotification (state, options) {
    Vue.set(state.hiddenSessionNotifications, getHiddenSessionNotificationId(options), true)
  },

  setMeetingUrl (state, meetingUrl) {
    state.meetingUrl = meetingUrl
  }
}

function dataToProject (id, data) {
  return new Project({ ...data, id })
}

function dataToSession (id, data) {
  return new Session({ ...data, id })
}

function connectionToData (connection) {
  const data = { ...connection }
  delete data.id
  data.created = serverTimestamp()
  return data
}

export const actions = {
  async initProject ({ commit, dispatch }, { projectId, sessionIds }) {
    commit('setProjectId', projectId)
    commit('clearSessions')

    const d = await getDoc(doc(getFirestore(), 'projects', projectId))
    if (!d.exists()) {
      commit('setProject', null)
      return null
    }

    const projectData = d.data()

    const form = await dispatch('auth/getLocalizedProjectForm', projectId, { root: true })

    const project = dataToProject(projectId, { ...projectData, form })
    commit('setProject', project)

    // Do not wait for additional data
    await Promise.all(sessionIds.map(sessionId => dispatch('initSession', { projectId, sessionId })))
  },

  async initSession ({ commit, getters, dispatch }, { projectId, sessionId }) {
    if (!projectId || !sessionId) {
      return
    }

    await subscribeOne(doc(getFirestore(), 'projects', projectId, 'sessions', sessionId, 'public', Session.id), async (doc) => {
      const session = doc ? dataToSession(sessionId, doc.data()) : null

      if (session && session.notification) {
        await session.notification.init({ dispatch })
      }

      commit('updateSession', { id: sessionId, session })
    })
  },

  async fetchMeetingUrl ({ commit, getters, rootGetters }, { meetingId, isWebinar }) {
    commit('setMeetingUrl', null)
    const result = await httpsCallable(getFunctions(), 'getMeetingUrl')({ projectId: getters.projectId, meetingId, isWebinar })
    commit('setMeetingUrl', result.data.url)
  },

  async createComment ({ state, dispatch }, { sessionId, message, inPerson }) {
    await dispatch('wdots/add', { event: 'tryPostMessage', message, inPerson }, { root: true })

    const comment = {
      message,
      inPerson,
      projectId: state.projectId,
      sessionId
    }

    await httpsCallable(getFunctions(), 'createComment')(comment)

    await dispatch('wdots/add', { event: 'createComment', comment }, { root: true })
  },

  async updateConnection ({ state, getters, commit, dispatch, rootGetters }, sessionId) {
    const projectId = state.projectId
    if (!projectId || !state.sessions) {
      return
    }

    const session = getters.session(sessionId)
    if (!session) {
      return
    }

    const user = rootGetters['auth/user']

    const connected = rootGetters['auth/signedIn'] && session.live
    if (connected !== state.connected) {
      commit('toggleConnected')

      if (connected) {
        const connection = new Connection({ uid: user.uid, email: user.email })
        await addDoc(collection(getFirestore(), 'projects', projectId, 'sessions', sessionId, 'connections'), connectionToData(connection))
      }
    }
  },

  hideSessionNotification ({ commit, getters, rootGetters }, session) {
    if (getters.sessionNotificationVisible(session)) {
      const user = rootGetters['auth/user']
      commit('hideSessionNotification', { uid: user.uid, sessionId: session.id, notificationId: session.notification.publicId })
    }
  },

  async generateDiploma ({ getters, dispatch }, { diplomaId, name }) {
    const result = await httpsCallable(getFunctions(), 'generateDiploma')({ projectId: getters.projectId, diplomaId, name })
    return result.data.message
  },

  async getDiploma ({ getters, dispatch }, { diplomaId }) {
    const result = await httpsCallable(getFunctions(), 'getDiploma')({ projectId: getters.projectId, diplomaId })
    return result.data
  }
}
