class Specialty {
  constructor (data) {
    if (!data.id) {
      throw new Error('Missing specialty id')
    }

    this.id = data.id
    Object.freeze(this)
  }

  get name () {
    return `v.specialty.${this.id}`
  }
}

const Specialties = {
  ALLERGIST: new Specialty({ id: 'allergist' }),
  ALGOLOGIST: new Specialty({ id: 'algologist' }),
  PATHOLOGICAL_ANAT: new Specialty({ id: 'pathological_anat' }),
  ANGIOLOGIST: new Specialty({ id: 'angiologist' }),
  BARIATRICIAN: new Specialty({ id: 'bariatrician' }),
  CARDIOLOGIST: new Specialty({ id: 'cardiologist' }),
  MAXILLOFACIAL_SURGEON: new Specialty({ id: 'maxillofacial_surgeon' }),
  RHINOLOGIST_SURGEON: new Specialty({ id: 'rhinologist_surgeon' }),
  TRANSPLANTOLOGIST_SURGEON: new Specialty({ id: 'transplantologist_surgeon' }),
  SURGEON: new Specialty({ id: 'surgeon' }),
  DENTAL_SURGEON: new Specialty({ id: 'dental_surgeon' }),
  GASTROENTEROLOGIST_SURGEON: new Specialty({ id: 'gastroenterologist_surgeon' }),
  OBSTETRIC_SURGEON: new Specialty({ id: 'obstetric_surgeon' }),
  ONCOLOGIST_SURGEON: new Specialty({ id: 'oncologist_surgeon' }),
  PLASTIC_SURGEON: new Specialty({ id: 'plastic_surgeon' }),
  VASCULAR_SURGEON: new Specialty({ id: 'vascular_surgeon' }),
  COLOPROCTOLOGIST: new Specialty({ id: 'coloproctologist' }),
  DERMATOLOGIST: new Specialty({ id: 'dermatologist' }),
  ENDOCRINOLOGIST: new Specialty({ id: 'endocrinologist' }),
  NURSE: new Specialty({ id: 'nurse' }),
  EPIDEMIOLOGIST: new Specialty({ id: 'epidemiologist' }),
  PHYSIOLOGIST: new Specialty({ id: 'physiologist' }),
  PHYSIOTHERAPIST: new Specialty({ id: 'physiotherapist' }),
  GASTROENTEROLOGISTS: new Specialty({ id: 'gastroenterologists' }),
  GENETICS: new Specialty({ id: 'genetics' }),
  GERIATRICIAN: new Specialty({ id: 'geriatrician' }),
  GYNECOLOGIST: new Specialty({ id: 'gynecologist' }),
  GYNECOLOGIST_ONCOLOGIST: new Specialty({ id: 'gynecologist_oncologist' }),
  HEMATOLOGIST: new Specialty({ id: 'hematologist' }),
  HOMEOPATH: new Specialty({ id: 'homeopath' }),
  INFECTOLOGIST: new Specialty({ id: 'infectologist' }),
  IMMUNOLOGIST: new Specialty({ id: 'immunologist' }),
  INTENSIVIST: new Specialty({ id: 'intensivist' }),
  RESPIRATORY_THERAPIST: new Specialty({ id: 'respiratory_therapist' }),
  INTEGRATED_MEDICINE: new Specialty({ id: 'integrated_medicine' }),
  LEGAL_MEDICINE: new Specialty({ id: 'legal_medicine' }),
  NUCLEAR_MEDICINE: new Specialty({ id: 'nuclear_medicine' }),
  FAMILY_DOCTOR: new Specialty({ id: 'family_doctor' }),
  GENERAL_PHYSICIAN: new Specialty({ id: 'general_physician' }),
  INTERNIST_DOCTOR: new Specialty({ id: 'internist_doctor' }),
  SPORTS_MEDICINE_PHYSICIAN: new Specialty({ id: 'sports_medicine_physician' }),
  NEPHROLOGIST: new Specialty({ id: 'nephrologist' }),
  NEONATOLOGIST: new Specialty({ id: 'neonatologist' }),
  PULMONOLOGIST: new Specialty({ id: 'pulmonologist' }),
  NEUROSURGEON: new Specialty({ id: 'neurosurgeon' }),
  NEUROLOGIST: new Specialty({ id: 'neurologist' }),
  NUTRITION: new Specialty({ id: 'nutrition' }),
  OBSTETRICIAN: new Specialty({ id: 'obstetrician' }),
  ODONTOLOGIST: new Specialty({ id: 'odontologist' }),
  OPHTHALMOLOGIST: new Specialty({ id: 'ophthalmologist' }),
  ONCOLOGIST: new Specialty({ id: 'oncologist' }),
  ORTHOPEDIST_TRAUMATOLOGIST: new Specialty({ id: 'orthopedist_traumatologist' }),
  OTOLARYNGOLOGIST: new Specialty({ id: 'otolaryngologist' }),
  PEDIATRICIAN: new Specialty({ id: 'pediatrician' }),
  CHIROPODIST: new Specialty({ id: 'chiropodist' }),
  PROCTOLOGIST: new Specialty({ id: 'proctologist' }),
  PSYCHIATRIST: new Specialty({ id: 'psychiatrist' }),
  RADIOLOGIST: new Specialty({ id: 'radiologist' }),
  RADIOTHERAPIST: new Specialty({ id: 'radiotherapist' }),
  REHABILITATION: new Specialty({ id: 'rehabilitation' }),
  RHEUMATOLOGIST: new Specialty({ id: 'rheumatologist' }),
  SEXOLOGIST: new Specialty({ id: 'sexologist' }),
  INTENSIVE_THERAPY: new Specialty({ id: 'intensive_therapy' }),
  TRANSPLANTOLOGIST: new Specialty({ id: 'transplantologist' }),
  EMERGENCY_PHYSICIAN: new Specialty({ id: 'emergency_physician' }),
  UROLOGIST: new Specialty({ id: 'urologist' }),
  RESPIRATORY_TRACTS: new Specialty({ id: 'respiratory_tracts' }),

  all: () => Object.values(Specialties)
    .filter(specialty => specialty instanceof Specialty)
    .sort(),

  fromString: (string) => {
    const specialty = Specialties.all().find(specialty => specialty.id === string)
    if (!specialty) {
      throw new Error(`Unknown specialty: ${string}`)
    }
    return specialty
  }
}
Object.freeze(Specialties)

export default Specialties
