import { onSnapshot } from 'firebase/firestore'
import _isNil from 'lodash/isNil'
import _trim from 'lodash/trim'
import _omit from 'lodash/omit'
import _mapKeys from 'lodash/mapKeys'
import _cloneDeep from 'lodash/cloneDeep'
import _get from 'lodash/get'
import _kebabCase from 'lodash/kebabCase'
import _shuffle from 'lodash/shuffle'
import _template from 'lodash/template'
import _isEqual from 'lodash/isEqual'
import * as moment from 'moment'
import { v4 as uuid } from 'uuid'

export function isBlank (value) {
  return _isNil(value) || _trim(value) === ''
}

export function blankToNull (value) {
  return isBlank(value) ? null : value
}

export function omit (data, ...fields) {
  return _omit(data, fields)
}

export function keysRemoveTrailingUnderscore (data) {
  return _mapKeys(data, (value, key) => key.startsWith('_') ? key.substring(1) : key)
}

export function get (object, path, defaultValue) {
  return _get(object, path, defaultValue)
}

export function cloneDeep (data) {
  return _cloneDeep(data)
}

export function capitalize (string) {
  if (!string) {
    return string
  }

  return string.charAt(0).toUpperCase() + string.substring(1)
}

export function isEqual (left, right, ignore = []) {
  return _isEqual(_omit(left, ignore), _omit(right, ignore))
}

export function difference (dateFrom, dateTo) {
  return moment(dateFrom).diff(moment(dateTo))
}

export function addDuration (timestamp, duration) {
  if (!timestamp || !duration) {
    return null
  }
  // eslint-disable-next-line import/namespace
  return moment(timestamp).add(moment.duration(duration, 'HH:mm')).toISOString()
}

export function addTrailingZeroIfNeeded (value) {
  return value < 10 ? `0${value}` : `${value}`
}

export function kebabCase (string) {
  return _kebabCase(string)
}

export function shuffle (collection) {
  return _shuffle(collection)
}

export function template (string) {
  return _template(string)
}

const SIZE_SLIGHT_MULTIPLIER = Object.freeze({
  xs: 0.5,
  s: 0.75,
  m: 1,
  l: 1.5,
  xl: 2
})

export function getSizeSlight (defaultSize, size) {
  if (!size) {
    size = 'm'
  }

  return SIZE_SLIGHT_MULTIPLIER[size] * defaultSize
}

export { uuid }

/* Below functions are needed since session storage is sometimes not available due to browser security settings or incognito mode */
export function retrieveItem (key) {
  try {
    return sessionStorage.getItem(key)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to retrieve from session storage: ${e.message}`)
  }
}

export function storeItem (key, value) {
  try {
    sessionStorage.setItem(key, value)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(`Unable to store into session storage: ${e.message}`)
  }
}

export function sleep (timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function isIE () {
  const ua = window.navigator.userAgent

  if (/MSIE|Trident/.test(ua)) {
    return true
  }

  const edgeMatch = /Edge\/(\d+)/.exec(ua)
  if (edgeMatch && parseInt(edgeMatch[1]) < 17) {
    return true
  }

  return false
}

export async function subscribe (query, { init, add, modify, remove, update }) {
  if (!add) {
    add = update
  }
  if (!modify) {
    modify = update
  }
  if (!remove) {
    remove = update
  }

  let unsubscribe = null
  let first = true

  try {
    await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(query, (snapshot) => {
        if (first) {
          try {
            const docs = snapshot.docChanges().map(change => change.doc)
            init(docs)
            resolve()
          } catch (error) {
            reject(error)
          } finally {
            first = false
          }
        } else {
          snapshot.docChanges().forEach((change) => {
            if (change.type === 'added') {
              add(change.doc)
            }
            if (change.type === 'modified') {
              modify(change.doc)
            }
            if (change.type === 'removed') {
              remove(change.doc)
            }
          })
        }
      }, reject)
    })
    return unsubscribe
  } catch (error) {
    if (unsubscribe) {
      unsubscribe()
    }
    throw error
  }
}

export async function subscribeOne (ref, update) {
  let unsubscribe = null
  let first = true

  try {
    await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(ref, (doc) => {
        try {
          update(doc && doc.exists() ? doc : null)
          if (first) {
            resolve()
          }
        } catch (error) {
          reject(error)
        } finally {
          first = false
        }
      })
    })

    return unsubscribe
  } catch (error) {
    if (unsubscribe) {
      unsubscribe()
    }
    throw error
  }
}

const ALPHANUMERIC = /^[a-zA-Z0-9]+$/
export function isAlphaNumeric (string) {
  return ALPHANUMERIC.test(string)
}

const EMAIL = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
export function isEmail (string) {
  return EMAIL.test(string)
}

export function localizeOptions ($t, options) {
  if (options.length === 0) {
    return []
  }
  if (typeof options[0] !== 'object' || !('label' in options[0]) || !('value' in options[0])) {
    return options.map(option => ({ text: $t(option), value: $t(option) }))
  }
  return options.map(option => ({ text: $t(option.label), value: option.value }))
}

export function getTheme (dark) {
  return dark != null ? { [dark ? 'dark' : 'light']: true } : {}
}

export function getPrefixedEmail (email, project) {
  return `project=${project.id}-${email}`
}

export function removeEmailPrefix (email) {
  return email && email.replace(/^(?:project=[^-]+-)|(?:snauth-)/, '')
}

export function toQueryString (data) {
  if (!data) {
    return ''
  }

  const params = new URLSearchParams()
  for (const [key, value] of Object.entries(data)) {
    params.append(key, value)
  }
  return params.toString()
}
